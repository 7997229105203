import { CoreModel } from '@salescore/core'
import { ModelProperty as CoreModelProperty } from '@salescore/core'
import { DimensionGroupProperty } from '@salescore/core'
import { EltChangeResultsChunk } from '@salescore/core'
import { EltChangesChunk } from '@salescore/core'
import { PolicyAction } from '@salescore/features'
import { PolicyPrincipal } from '@salescore/features'
import { PolicySubject } from '@salescore/features'
import { ValidationFunction } from '@salescore/buff-common'
import { ViewConfig } from '@salescore/core'
import { ViewQuery } from '@salescore/core'
import { ViewQueryResult } from '@salescore/core'
import { ViewUI } from '@salescore/core'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  CoreModel: CoreModel
  CoreModelProperty: CoreModelProperty
  Date: string
  DateTime: string
  DimensionGroupProperty: DimensionGroupProperty
  EltChangeResultsChunk: EltChangeResultsChunk
  EltChangesChunk: EltChangesChunk
  JSON: any
  PolicyAction: PolicyAction
  PolicyPrincipal: PolicyPrincipal
  PolicySubject: PolicySubject
  ValidationFunction: ValidationFunction
  ViewConfig: ViewConfig
  ViewQuery: ViewQuery
  ViewQueryResult: ViewQueryResult
  ViewUI: ViewUI
}

export type AddSheetCustomModelPropertyInput = {
  currentViewConfigFieldProperties: Array<NodePropertyNameInput>
  label: Scalars['String']
  meta?: InputMaybe<Scalars['String']>
  nodeName: Scalars['String']
  selectOptions?: InputMaybe<Scalars['JSON']>
  type: Scalars['String']
  viewId: Scalars['String']
}

export type AddSheetCustomModelPropertyResponse = {
  __typename?: 'AddSheetCustomModelPropertyResponse'
  addedProperty: Scalars['CoreModelProperty']
  sheetCustomModel: SheetCustomModel
}

export const AdminExecuteScriptMutationScriptNameEnum = {
  ImportSampleReports: 'importSampleReports',
} as const

export type AdminExecuteScriptMutationScriptNameEnum =
  (typeof AdminExecuteScriptMutationScriptNameEnum)[keyof typeof AdminExecuteScriptMutationScriptNameEnum]
export type AdminExtractCallingMetadataJobSettingInput = {
  concurrency?: InputMaybe<Scalars['Int']>
  executedAt?: InputMaybe<Scalars['DateTime']>
  promptForSpeechToText?: InputMaybe<Scalars['JSON']>
  promptToExtractMetadata?: InputMaybe<Scalars['JSON']>
  promptToRefineText?: InputMaybe<Scalars['JSON']>
  targetEmails?: InputMaybe<Scalars['JSON']>
  version: Scalars['String']
}

export type AdminInvitationCreateInput = {
  email: Scalars['String']
  organizationId: Scalars['String']
  planForSync: UserPlanEnum
  planForVis: UserPlanEnum
  provider: IdentityProviderEnum
  role: Scalars['String']
}

export type AdminInvitationInput = {
  id: Scalars['ID']
  planForSync?: InputMaybe<UserPlanEnum>
  planForVis?: InputMaybe<UserPlanEnum>
  role?: InputMaybe<Scalars['String']>
}

export type AdminOrganization = {
  __typename?: 'AdminOrganization'
  adminMemo: Scalars['String']
  createdAt: Scalars['DateTime']
  currentPlan: SubscriptionPlanEnum
  deletedAt?: Maybe<Scalars['DateTime']>
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  invitations: Array<Invitation>
  isEarlyReleaseTarget: Scalars['Boolean']
  jobQueues: Array<JobQueue>
  name: Scalars['String']
  organizationFeatures: Array<OrganizationFeature>
  organizationSetting: OrganizationSetting
  sources: Array<Source>
  status: OrganizationStatusEnum
  subscriptions: Array<Subscription>
  updatedAt: Scalars['DateTime']
  users: Array<V2User>
}

export type AdminOrganizationInput = {
  adminMemo: Scalars['String']
  name: Scalars['String']
  status: OrganizationStatusEnum
}

export type AdminQueueJobInput = {
  delaySec?: InputMaybe<Scalars['Int']>
  jobArg: Scalars['JSON']
  jobName: Scalars['String']
  organizationId?: InputMaybe<Scalars['String']>
}

export type AdminUserInput = {
  deleted?: InputMaybe<Scalars['Boolean']>
  id: Scalars['ID']
  isManager?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  plan?: InputMaybe<UserPlanEnum>
  planForSync?: InputMaybe<UserPlanEnum>
  planForVis?: InputMaybe<UserPlanEnum>
  role?: InputMaybe<Scalars['String']>
}

export type AllowedIp = {
  __typename?: 'AllowedIp'
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  ip: Scalars['String']
}

export type AllowedIpInput = {
  deleted?: InputMaybe<Scalars['Boolean']>
  description?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  ip: Scalars['String']
}

export type AverageRenderDuration = {
  __typename?: 'AverageRenderDuration'
  averageDuration?: Maybe<Scalars['Int']>
  isRecordsCached?: Maybe<Scalars['Boolean']>
  sampleSize: Scalars['Int']
  viewId: Scalars['String']
}

export type BusinessDay = {
  __typename?: 'BusinessDay'
  date: Scalars['Date']
  id: Scalars['ID']
  isBusinessDay: Scalars['Boolean']
}

export type BusinessDayInput = {
  date: Scalars['Date']
  deleted?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['ID']>
  isBusinessDay: Scalars['Boolean']
}

export type ConfiguredCatalog = {
  __typename?: 'ConfiguredCatalog'
  streams: Array<Stream>
}

export type Connection = {
  __typename?: 'Connection'
  active: Scalars['Boolean']
  createdAt: Scalars['DateTime']
  eltConfig: EltConfig
  eltModels: Array<EltModel>
  eltType: ConnectionEltTypeEnum
  id: Scalars['ID']
  organizationId: Scalars['String']
  source: Source
  sourceId: Scalars['String']
  streams: Array<Stream>
  updatedAt: Scalars['DateTime']
}

export const ConnectionEltTypeEnum = {
  Elt: 'elt',
  EltWithUserSources: 'eltWithUserSources',
  NoElt: 'noElt',
} as const

export type ConnectionEltTypeEnum = (typeof ConnectionEltTypeEnum)[keyof typeof ConnectionEltTypeEnum]
export type ConnectionForJobQueue = {
  __typename?: 'ConnectionForJobQueue'
  active: Scalars['Boolean']
  id: Scalars['ID']
  organizationId: Scalars['String']
  sourceId: Scalars['String']
  sourceProvider: SourceProviderEnum
}

export type ConnectionUpsertInput = {
  active: Scalars['Boolean']
  organizationId: Scalars['ID']
  sourceId: Scalars['ID']
}

export type ConvertSalesforceLeadInput = {
  accountId?: InputMaybe<Scalars['String']>
  accountName?: InputMaybe<Scalars['String']>
  contactFirstName?: InputMaybe<Scalars['String']>
  contactId?: InputMaybe<Scalars['String']>
  contactLastName?: InputMaybe<Scalars['String']>
  convertedStatus: Scalars['String']
  doNotCreateOpportunity?: InputMaybe<Scalars['Boolean']>
  leadId: Scalars['String']
  opportunityName?: InputMaybe<Scalars['String']>
  overwriteLeadSource?: InputMaybe<Scalars['Boolean']>
  ownerId?: InputMaybe<Scalars['String']>
  sendNotificationEmail?: InputMaybe<Scalars['Boolean']>
}

export type ConvertSalesforceLeadResult = {
  __typename?: 'ConvertSalesforceLeadResult'
  accountId?: Maybe<Scalars['String']>
  contactId?: Maybe<Scalars['String']>
  errors?: Maybe<Scalars['String']>
  leadId: Scalars['String']
  opportunityId?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type ConvertingSalesforceLead = {
  __typename?: 'ConvertingSalesforceLead'
  accountName?: Maybe<Scalars['String']>
  candidateAccounts: Array<ConvertingSalesforceLeadAccountCandidate>
  candidateContacts: Array<ConvertingSalesforceLeadContactCandidate>
  firstName?: Maybe<Scalars['String']>
  id: Scalars['String']
  isConverted: Scalars['Boolean']
  lastName?: Maybe<Scalars['String']>
}

export type ConvertingSalesforceLeadAccountCandidate = {
  __typename?: 'ConvertingSalesforceLeadAccountCandidate'
  billingAddress?: Maybe<Scalars['String']>
  id: Scalars['String']
  name: Scalars['String']
  ownerName?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  website?: Maybe<Scalars['String']>
}

export type ConvertingSalesforceLeadContactCandidate = {
  __typename?: 'ConvertingSalesforceLeadContactCandidate'
  accountName?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  firstName: Scalars['String']
  id: Scalars['String']
  lastName: Scalars['String']
  ownerName?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type ConvertingSalesforceLeadRelated = {
  __typename?: 'ConvertingSalesforceLeadRelated'
  leadStatuses: Array<SalesforceLeadStatus>
  leads: Array<ConvertingSalesforceLead>
}

export type CreateViewInput = {
  config: Scalars['ViewConfig']
  eltModelId?: InputMaybe<Scalars['String']>
  emoji?: InputMaybe<Scalars['String']>
  locked?: InputMaybe<Scalars['Boolean']>
  name: Scalars['String']
  private?: InputMaybe<Scalars['Boolean']>
  viewGroupId?: InputMaybe<Scalars['String']>
}

export type CrossTabulateQueriesResult = {
  __typename?: 'CrossTabulateQueriesResult'
  result: Scalars['JSON']
}

export type Cursor = {
  __typename?: 'Cursor'
  lastSyncedAt?: Maybe<Scalars['String']>
  streamName: Scalars['String']
  transformType?: Maybe<EltModelTransformTypeEnum>
}

export type CustomRolePoliciesInput = {
  actionsWithPermission: Array<CustomRolePolicyInputActionsWithPermission>
  userRole: Scalars['String']
}

export type CustomRolePolicyInputActionsWithPermission = {
  action: Scalars['PolicyAction']
  permission: Scalars['Boolean']
}

export type DashboardItemInput = {
  deleted?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['ID']>
  kpiIds: Array<Scalars['String']>
  name: Scalars['String']
  rank: Scalars['Int']
  shouldOrderByKpiIds: Scalars['Boolean']
  userGroupIds: Array<Scalars['String']>
}

export type DashboardItemV2 = {
  __typename?: 'DashboardItemV2'
  id: Scalars['ID']
  kpis: Array<Kpi>
  name: Scalars['String']
  queryParameterConfigs: Array<QueryParameterConfig>
  rank: Scalars['Int']
  shouldOrderByKpiIds: Scalars['Boolean']
  userGroups: Array<UserGroup>
}

export type DependentStream = {
  __typename?: 'DependentStream'
  sourceName: Scalars['String']
  streamName: Scalars['String']
}

export type DependentStreamInput = {
  sourceName: Scalars['String']
  streamName: Scalars['String']
}

export type Destination = {
  __typename?: 'Destination'
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  provider: DestinationProviderEnum
  updatedAt: Scalars['DateTime']
}

export const DestinationProviderEnum = {
  BigQuery: 'big_query',
  Postgres: 'postgres',
} as const

export type DestinationProviderEnum = (typeof DestinationProviderEnum)[keyof typeof DestinationProviderEnum]
export type DimensionGroup = {
  __typename?: 'DimensionGroup'
  id: Scalars['ID']
  label: Scalars['String']
  properties: Array<Scalars['DimensionGroupProperty']>
}

export type DimensionGroupCreateInput = {
  label: Scalars['String']
  properties: Scalars['JSON']
}

export type DimensionGroupUpdateInput = {
  id: Scalars['ID']
  label: Scalars['String']
  properties: Scalars['JSON']
}

export type EltConfig = {
  __typename?: 'EltConfig'
  sourceConfigs?: Maybe<Array<EltConfigSourceConfig>>
  sourceNamesWhitelist?: Maybe<Array<Scalars['String']>>
}

export type EltConfigInput = {
  sourceConfigs?: InputMaybe<Array<EltConfigSourceConfigInput>>
  sourceNamesWhitelist?: InputMaybe<Array<Scalars['String']>>
}

export type EltConfigSourceConfig = {
  __typename?: 'EltConfigSourceConfig'
  propertyNamesWhitelist?: Maybe<Array<Scalars['String']>>
  sourceName: Scalars['String']
}

export type EltConfigSourceConfigInput = {
  propertyNamesWhitelist?: InputMaybe<Array<Scalars['String']>>
  sourceName: Scalars['String']
}

export type EltModel = {
  __typename?: 'EltModel'
  connectionId?: Maybe<Scalars['String']>
  eltModelChange?: Maybe<EltModelChange>
  eltModelError?: Maybe<EltModelError>
  extract?: Maybe<Scalars['JSON']>
  id: Scalars['ID']
  model: Scalars['CoreModel']
  name: Scalars['String']
  syncInvalidatedAt?: Maybe<Scalars['DateTime']>
  syncedAt?: Maybe<Scalars['DateTime']>
  transform?: Maybe<EltModelTransform>
  transformInvalidatedAt?: Maybe<Scalars['DateTime']>
  transformedAt?: Maybe<Scalars['DateTime']>
  type: EltModelTypeEnum
}

export type EltModelChange = {
  __typename?: 'EltModelChange'
  id: Scalars['ID']
}

export type EltModelCreateInput = {
  connectionId: Scalars['String']
  model: Scalars['CoreModel']
  name: Scalars['String']
  transform: EltModelTransformInput
  type: EltModelTypeEnum
}

export type EltModelError = {
  __typename?: 'EltModelError'
  id: Scalars['ID']
}

export type EltModelTransform = {
  __typename?: 'EltModelTransform'
  sql: Scalars['String']
  sqlToDelete?: Maybe<Scalars['String']>
  type: EltModelTransformTypeEnum
}

export type EltModelTransformInput = {
  sql: Scalars['String']
  type: EltModelTransformTypeEnum
}

export const EltModelTransformTypeEnum = {
  MaterializedView: 'materialized_view',
  Snapshot: 'snapshot',
  SnapshotSequence: 'snapshot_sequence',
  Table: 'table',
  View: 'view',
} as const

export type EltModelTransformTypeEnum = (typeof EltModelTransformTypeEnum)[keyof typeof EltModelTransformTypeEnum]
export const EltModelTypeEnum = {
  Source: 'source',
  System: 'system',
  UserCustomizedDerived: 'userCustomizedDerived',
  UserCustomizedSalescoreSource: 'userCustomizedSalescoreSource',
  UserCustomizedSnapshot: 'userCustomizedSnapshot',
  UserCustomizedSnapshotSequence: 'userCustomizedSnapshotSequence',
} as const

export type EltModelTypeEnum = (typeof EltModelTypeEnum)[keyof typeof EltModelTypeEnum]
export type EltModelUpdateInput = {
  connectionId: Scalars['String']
  id: Scalars['ID']
  model: Scalars['CoreModel']
  transform: EltModelTransformInput
}

export type ExtractCallingMetadataJobSetting = {
  __typename?: 'ExtractCallingMetadataJobSetting'
  concurrency: Scalars['Int']
  executedAt?: Maybe<Scalars['DateTime']>
  id: Scalars['ID']
  organizationId: Scalars['String']
  promptForSpeechToText?: Maybe<Scalars['JSON']>
  promptToExtractMetadata?: Maybe<Scalars['JSON']>
  promptToRefineText?: Maybe<Scalars['JSON']>
  targetEmails?: Maybe<Scalars['JSON']>
  type: Scalars['String']
  version: Scalars['String']
}

export type Favorite = {
  __typename?: 'Favorite'
  id: Scalars['ID']
  rank: Scalars['Int']
  userId: Scalars['String']
  viewGroupId?: Maybe<Scalars['String']>
  viewId?: Maybe<Scalars['String']>
}

export type FavoriteCreateInput = {
  viewGroupId?: InputMaybe<Scalars['String']>
  viewId?: InputMaybe<Scalars['String']>
}

export type FeatureFlag = {
  __typename?: 'FeatureFlag'
  feature: Scalars['String']
  organizationId: Scalars['ID']
  organizationName: Scalars['String']
}

export type GoalConfig = {
  __typename?: 'GoalConfig'
  config?: Maybe<Scalars['ViewConfig']>
  createdBy?: Maybe<ViewCreatedBy>
  dashboardView?: Maybe<GoalConfigDashboardView>
  dashboardViewId?: Maybe<Scalars['String']>
  goalDimension1?: Maybe<GoalDimension>
  goalDimension1Id?: Maybe<Scalars['String']>
  goalDimension2?: Maybe<GoalDimension>
  goalDimension2Id?: Maybe<Scalars['String']>
  goalDimension3?: Maybe<GoalDimension>
  goalDimension3Id?: Maybe<Scalars['String']>
  goalDimension4?: Maybe<GoalDimension>
  goalDimension4Id?: Maybe<Scalars['String']>
  goalDimension5?: Maybe<GoalDimension>
  goalDimension5Id?: Maybe<Scalars['String']>
  id: Scalars['ID']
  kpiViews: Array<GoalConfigKpiView>
  name: Scalars['String']
  rank: Scalars['Int']
  timeSpanType: GoalConfigTimeSpanTypeEnum
  userGroupIdsConfig: Array<Scalars['String']>
  userIdsConfig: Array<Scalars['String']>
  userOrUserGroupIds: Array<Scalars['String']>
  userType: GoalConfigUserTypeEnum
}

export type GoalConfigCreateInput = {
  dashboardViewId: Scalars['String']
  goalDimension1Id?: InputMaybe<Scalars['String']>
  goalDimension2Id?: InputMaybe<Scalars['String']>
  goalDimension3Id?: InputMaybe<Scalars['String']>
  goalDimension4Id?: InputMaybe<Scalars['String']>
  goalDimension5Id?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  timeSpanType: GoalConfigTimeSpanTypeEnum
  userGroupIdsConfig: Array<Scalars['String']>
  userIdsConfig: Array<Scalars['String']>
  userType: GoalConfigUserTypeEnum
}

export type GoalConfigDashboardView = {
  __typename?: 'GoalConfigDashboardView'
  id: Scalars['ID']
  name: Scalars['String']
}

export type GoalConfigKpiView = {
  __typename?: 'GoalConfigKpiView'
  aggregationFunction: GoalConfigKpiViewAggregationFunctionType
  id: Scalars['ID']
  name: Scalars['String']
}

export const GoalConfigKpiViewAggregationFunctionType = {
  Avg: 'avg',
  Sum: 'sum',
} as const

export type GoalConfigKpiViewAggregationFunctionType =
  (typeof GoalConfigKpiViewAggregationFunctionType)[keyof typeof GoalConfigKpiViewAggregationFunctionType]
export const GoalConfigTimeSpanTypeEnum = {
  Day: 'day',
  Month: 'month',
  None: 'none',
  Week: 'week',
} as const

export type GoalConfigTimeSpanTypeEnum = (typeof GoalConfigTimeSpanTypeEnum)[keyof typeof GoalConfigTimeSpanTypeEnum]
export type GoalConfigUpdateInput = {
  config?: InputMaybe<Scalars['ViewConfig']>
  id: Scalars['ID']
  name: Scalars['String']
  rank: Scalars['Int']
  userGroupIdsConfig: Array<Scalars['String']>
  userIdsConfig: Array<Scalars['String']>
}

export const GoalConfigUserTypeEnum = {
  Group: 'group',
  None: 'none',
  User: 'user',
} as const

export type GoalConfigUserTypeEnum = (typeof GoalConfigUserTypeEnum)[keyof typeof GoalConfigUserTypeEnum]
export type GoalDimension = {
  __typename?: 'GoalDimension'
  id: Scalars['ID']
  label: Scalars['String']
  name: Scalars['String']
  selectOptions: Array<Scalars['String']>
  selectOptionsV3: Array<GoalDimensionSelectOption>
}

export type GoalDimensionInput = {
  deleted?: InputMaybe<Scalars['Boolean']>
  dimensionGroupIds?: InputMaybe<Array<Scalars['String']>>
  id?: InputMaybe<Scalars['ID']>
  label: Scalars['String']
  name: Scalars['String']
  selectOptions: Array<GoalDimensionSelectOptionInput>
}

export type GoalDimensionSelectOption = {
  __typename?: 'GoalDimensionSelectOption'
  label: Scalars['String']
  value: Scalars['String']
}

export type GoalDimensionSelectOptionInput = {
  label: Scalars['String']
  value: Scalars['String']
}

export type GptResult = {
  __typename?: 'GptResult'
  completionTokens: Scalars['Int']
  message: Scalars['String']
  promptTokens: Scalars['Int']
  totalTokens: Scalars['Int']
}

export type HubRecord = {
  __typename?: 'HubRecord'
  data: Scalars['JSON']
  id: Scalars['ID']
  streamName: Scalars['String']
}

export type I18nSource = {
  __typename?: 'I18nSource'
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  lang: LangEnum
  name?: Maybe<Scalars['String']>
  organizationId: Scalars['String']
  provider: SourceProviderEnum
  updatedAt: Scalars['DateTime']
}

export type Identity = {
  __typename?: 'Identity'
  createdAt: Scalars['DateTime']
  email: Scalars['String']
  id: Scalars['ID']
  provider: IdentityProviderEnum
  updatedAt: Scalars['DateTime']
}

export type IdentityInput = {
  auth0UserId: Scalars['ID']
  email: Scalars['String']
  name: Scalars['String']
  oldId: Scalars['ID']
  provider: Scalars['String']
  role: Scalars['String']
}

export const IdentityProviderEnum = {
  Auth0: 'auth0',
  GoogleOauth2: 'googleOauth2',
  Hubspot: 'hubspot',
  Salesforce: 'salesforce',
  Saml: 'saml',
  Zoho: 'zoho',
} as const

export type IdentityProviderEnum = (typeof IdentityProviderEnum)[keyof typeof IdentityProviderEnum]
export type Invitation = {
  __typename?: 'Invitation'
  createdAt: Scalars['DateTime']
  email: Scalars['String']
  id: Scalars['ID']
  plan: UserPlanEnum
  planForSync: UserPlanEnum
  planForVis: UserPlanEnum
  provider: IdentityProviderEnum
  role: Scalars['String']
}

export type InvitationInput = {
  deleted?: InputMaybe<Scalars['Boolean']>
  email: Scalars['String']
  id?: InputMaybe<Scalars['ID']>
  planForSync?: InputMaybe<Scalars['Boolean']>
  planForVis?: InputMaybe<Scalars['Boolean']>
  provider: IdentityProviderEnum
  role: Scalars['String']
}

export type Job = {
  __typename?: 'Job'
  connectionId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  logs?: Maybe<Array<Maybe<Scalars['String']>>>
  status: JobTypeEnum
}

export type JobQueue = {
  __typename?: 'JobQueue'
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  jobArg: Scalars['JSON']
  jobName: Scalars['String']
  jobQueueLogs: Array<JobQueueLog>
  jobResult?: Maybe<Scalars['JSON']>
  jobWorkerId?: Maybe<Scalars['String']>
  key: Scalars['String']
  message?: Maybe<Scalars['String']>
  organization?: Maybe<AdminOrganization>
  organizationId?: Maybe<Scalars['String']>
  parentJobQueueId?: Maybe<Scalars['String']>
  priority: Scalars['Int']
  processId?: Maybe<Scalars['Int']>
  scheduledAt: Scalars['DateTime']
  startedAt?: Maybe<Scalars['DateTime']>
  status: JobQueueStatusEnum
  stoppedAt?: Maybe<Scalars['DateTime']>
  updatedAt: Scalars['DateTime']
}

export type JobQueueLog = {
  __typename?: 'JobQueueLog'
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  jobQueueId: Scalars['String']
  jobWorkerId: Scalars['String']
  level: JobQueueLogLevelEnum
  message: Scalars['String']
  tags: Array<Scalars['String']>
}

export const JobQueueLogLevelEnum = {
  Debug: 'debug',
  Error: 'error',
  Info: 'info',
  Warn: 'warn',
} as const

export type JobQueueLogLevelEnum = (typeof JobQueueLogLevelEnum)[keyof typeof JobQueueLogLevelEnum]
export const JobQueueStatusEnum = {
  Completed: 'completed',
  Error: 'error',
  Killed: 'killed',
  Running: 'running',
  Waiting: 'waiting',
} as const

export type JobQueueStatusEnum = (typeof JobQueueStatusEnum)[keyof typeof JobQueueStatusEnum]
export const JobTypeEnum = {
  Created: 'created',
  Failed: 'failed',
  Retrying: 'retrying',
  Succeeded: 'succeeded',
} as const

export type JobTypeEnum = (typeof JobTypeEnum)[keyof typeof JobTypeEnum]
export type JobWorker = {
  __typename?: 'JobWorker'
  childProcessIds: Scalars['JSON']
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  message?: Maybe<Scalars['String']>
  processId: Scalars['Int']
  status: JobWorkerStatusEnum
  stoppedAt?: Maybe<Scalars['DateTime']>
  updatedAt: Scalars['DateTime']
}

export const JobWorkerStatusEnum = {
  Error: 'error',
  Running: 'running',
  Stopped: 'stopped',
} as const

export type JobWorkerStatusEnum = (typeof JobWorkerStatusEnum)[keyof typeof JobWorkerStatusEnum]
export type JoinSlackBotToChannelMutationInput = {
  defaultChannelId?: InputMaybe<Scalars['String']>
  organizationId: Scalars['String']
}

export type JoinSlackBotToChannelResponse = {
  __typename?: 'JoinSlackBotToChannelResponse'
  status: Scalars['String']
}

export type Kpi = {
  __typename?: 'Kpi'
  abbreviation_type: KpiAbbreviationTypeEnum
  achievement_status_calculation_type: KpiAchievementStatusCalculationTypeEnum
  achievement_status_target_value_type: KpiAchievementStatusTargetValueTypeEnum
  description?: Maybe<Scalars['String']>
  display_format?: Maybe<KpiDisplayFormatEnum>
  goal_type: KpiGoalTypeEnum
  icon?: Maybe<Scalars['String']>
  id: Scalars['ID']
  kind: KpiKindEnum
  name: Scalars['String']
  rank: Scalars['Int']
  time_dependent: Scalars['Boolean']
  user_dependent: Scalars['Boolean']
}

export const KpiAbbreviationTypeEnum = {
  AbbreviateMillion: 'abbreviate_million',
  AbbreviateTenThousand: 'abbreviate_ten_thousand',
  AbbreviateThousand: 'abbreviate_thousand',
  Automatic: 'automatic',
  NoAbbreviation: 'no_abbreviation',
} as const

export type KpiAbbreviationTypeEnum = (typeof KpiAbbreviationTypeEnum)[keyof typeof KpiAbbreviationTypeEnum]
export const KpiAchievementStatusCalculationTypeEnum = {
  GreaterThanOrEqual: 'greater_than_or_equal',
  LessThanOrEqual: 'less_than_or_equal',
} as const

export type KpiAchievementStatusCalculationTypeEnum =
  (typeof KpiAchievementStatusCalculationTypeEnum)[keyof typeof KpiAchievementStatusCalculationTypeEnum]
export const KpiAchievementStatusTargetValueTypeEnum = {
  AverageProgress: 'average_progress',
  Goal: 'goal',
  Zero: 'zero',
} as const

export type KpiAchievementStatusTargetValueTypeEnum =
  (typeof KpiAchievementStatusTargetValueTypeEnum)[keyof typeof KpiAchievementStatusTargetValueTypeEnum]
export const KpiDisplayFormatEnum = {
  Percent: 'percent',
  Yen: 'yen',
} as const

export type KpiDisplayFormatEnum = (typeof KpiDisplayFormatEnum)[keyof typeof KpiDisplayFormatEnum]
export const KpiGoalTypeEnum = {
  Amount: 'amount',
  Ratio: 'ratio',
} as const

export type KpiGoalTypeEnum = (typeof KpiGoalTypeEnum)[keyof typeof KpiGoalTypeEnum]
export type KpiInput = {
  abbreviation_type: KpiAbbreviationTypeEnum
  achievement_status_calculation_type: KpiAchievementStatusCalculationTypeEnum
  achievement_status_target_value_type: KpiAchievementStatusTargetValueTypeEnum
  deleted?: InputMaybe<Scalars['Boolean']>
  description?: InputMaybe<Scalars['String']>
  display_format?: InputMaybe<KpiDisplayFormatEnum>
  goal_type: KpiGoalTypeEnum
  icon?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  kind: KpiKindEnum
  name: Scalars['String']
  rank: Scalars['Int']
  report?: InputMaybe<ReportInput>
  time_dependent: Scalars['Boolean']
  user_dependent: Scalars['Boolean']
}

export const KpiKindEnum = {
  Action: 'action',
  Main: 'main',
  Process: 'process',
} as const

export type KpiKindEnum = (typeof KpiKindEnum)[keyof typeof KpiKindEnum]
export const LangEnum = {
  En: 'en',
  Ja: 'ja',
} as const

export type LangEnum = (typeof LangEnum)[keyof typeof LangEnum]
export type MixedUser = {
  __typename?: 'MixedUser'
  id: Scalars['ID']
  invitation?: Maybe<Invitation>
  resourceUser: ResourceUser
  user?: Maybe<V2User>
}

export type ModelProperty = {
  __typename?: 'ModelProperty'
  creatable?: Maybe<Scalars['Boolean']>
  label: Scalars['String']
  meta?: Maybe<PropertyMetaEnum>
  name: Scalars['String']
  referenceTo?: Maybe<Array<PropertyReferenceTo>>
  selectOptions?: Maybe<Array<ModelPropertySelectOption>>
  trackable?: Maybe<Scalars['Boolean']>
  type: PropertyTypeEnum
  updatable?: Maybe<Scalars['Boolean']>
  validations?: Maybe<Array<ValidationRule>>
  write?: Maybe<ModelPropertyWrite>
}

export type ModelPropertySelectOption = {
  __typename?: 'ModelPropertySelectOption'
  color?: Maybe<Scalars['String']>
  icon?: Maybe<Scalars['String']>
  label: Scalars['String']
  rule?: Maybe<Scalars['ValidationFunction']>
  value: Scalars['String']
}

export type ModelPropertyWrite = {
  __typename?: 'ModelPropertyWrite'
  sourcePropertyName: Scalars['String']
}

export type MoveViewGroupInput = {
  id: Scalars['ID']
  private?: InputMaybe<Scalars['Boolean']>
  rank: Scalars['Int']
  viewGroupId?: InputMaybe<Scalars['String']>
  viewGroupIdForPrivateKpis?: InputMaybe<Scalars['String']>
}

export type MoveViewInput = {
  id: Scalars['ID']
  private?: InputMaybe<Scalars['Boolean']>
  rank: Scalars['Int']
  viewGroupId?: InputMaybe<Scalars['String']>
  viewGroupIdForPrivateKpis?: InputMaybe<Scalars['String']>
}

export type Mutation = {
  __typename?: 'Mutation'
  addSheetCustomModelPropertyV2: AddSheetCustomModelPropertyResponse
  adminClearJobs?: Maybe<Scalars['String']>
  adminCreateInvitation?: Maybe<Invitation>
  adminCreateOrganization: AdminOrganization
  adminDeleteOrganization?: Maybe<AdminOrganization>
  adminExecuteScript: Scalars['String']
  adminInvalidateEltModel?: Maybe<JobQueue>
  adminKillQueue?: Maybe<JobQueue>
  adminQueueJob?: Maybe<JobQueue>
  adminRestoreDeletedOrganization?: Maybe<AdminOrganization>
  adminRetryQueue?: Maybe<JobQueue>
  adminUpdateEltModelFilter: Connection
  adminUpdateInvitation: Invitation
  adminUpdateOrganization?: Maybe<AdminOrganization>
  adminUpdateOrganizationFeatures: AdminOrganization
  adminUpdateQueue?: Maybe<JobQueue>
  adminUpdateSubscription?: Maybe<Subscription>
  adminUpdateUser?: Maybe<V2User>
  adminUpsertExtractCallingMetadataJobSetting: ExtractCallingMetadataJobSetting
  applyEltModelChange: Array<EltModel>
  archiveView?: Maybe<View>
  archiveViewGroup?: Maybe<ViewGroup>
  convertSalesforceLeads: Array<ConvertSalesforceLeadResult>
  copyView: View
  createDimensionGroup?: Maybe<DimensionGroup>
  createEltModel: EltModel
  createFavorite?: Maybe<Favorite>
  createGoalConfig: GoalConfig
  createIdentity?: Maybe<Identity>
  createKintoneUserSource?: Maybe<UserSource>
  createOauthI18nSource?: Maybe<I18nSource>
  createOauthSourceV2?: Maybe<Source>
  createOauthUserSource?: Maybe<UserSource>
  createPolicyUserRole: PolicyUserRole
  createReverseElt?: Maybe<ReverseElt>
  createSharedUrl?: Maybe<SharedUrl>
  createSheetThreadComment: SheetThreadComment
  createSlackOrgIntegration: SlackOrgIntegration
  createSlackUserIntegration: SlackUserIntegration
  createSnapshotModel: EltModel
  createSource?: Maybe<Source>
  createView: View
  createViewGroup?: Maybe<ViewGroup>
  debugThrowSampleError: Scalars['String']
  deleteDimensionGroup?: Maybe<DimensionGroup>
  deleteEltModel?: Maybe<EltModel>
  deleteFavorite?: Maybe<Favorite>
  deleteGoalConfig?: Maybe<GoalConfig>
  deleteI18nSource?: Maybe<I18nSource>
  deletePolicyUserRole: PolicyUserRole
  deleteReverseElt?: Maybe<ReverseElt>
  deleteSlackOrgIntegration: SlackOrgIntegration
  deleteSlackUserIntegration: SlackUserIntegration
  deleteSnapshotModel: EltModel
  deleteSource?: Maybe<Source>
  deleteUserSource?: Maybe<UserSource>
  deleteView?: Maybe<View>
  deleteViewGroup?: Maybe<ViewGroup>
  initializeGoals?: Maybe<GoalConfig>
  invalidateEltModelSync: EltModel
  joinSlackBotToChannel: JoinSlackBotToChannelResponse
  markSheetThreadCommentNotificationsAsRead: Scalars['Boolean']
  moveViewGroups?: Maybe<Array<ViewGroup>>
  moveViews?: Maybe<Array<View>>
  queueImportSourceUsersQueue?: Maybe<JobQueue>
  queueReverseEltJob?: Maybe<JobQueue>
  recordRenderDuration?: Maybe<RenderDuration>
  replaceCustomRolePolicies: Array<Policy>
  replaceSubjectPolicies: Array<Policy>
  syncConnection?: Maybe<JobQueue>
  syncModels: Array<JobQueue>
  syncSourceView?: Maybe<Array<ViewGroup>>
  takeSnapshot: SnapshotSequenceModel
  updateDimensionGroup?: Maybe<DimensionGroup>
  updateEltConfig?: Maybe<Connection>
  updateEltModel?: Maybe<EltModel>
  updateGoalConfig?: Maybe<GoalConfig>
  updateMyUser: MyUser
  updateOrganizationV2: V2Organization
  updatePolicyUserRole: PolicyUserRole
  updateReverseElt: ReverseElt
  updateSheetCustomModelProperty: SheetCustomModel
  updateSheetThreadCommentNotification: SheetThreadCommentNotification
  updateSlackOrgIntegration: SlackOrgIntegration
  updateSlackUserIntegration: SlackUserIntegration
  updateSource?: Maybe<Source>
  updateUserGroupByCsv?: Maybe<UpdateUserGroupByCsvResponse>
  updateUserGroupIdsByCsv?: Maybe<UpdateUserGroupIdByCsvResponse>
  updateView: View
  updateViewGroups?: Maybe<Array<ViewGroup>>
  updateViews?: Maybe<Array<View>>
  upsertAllowedIps: Array<Maybe<AllowedIp>>
  upsertBusinessDay?: Maybe<BusinessDay>
  upsertConnection?: Maybe<Connection>
  upsertDashboardItemV2?: Maybe<DashboardItemV2>
  upsertGoalDimension: Array<GoalDimension>
  upsertGoalsByCsv?: Maybe<UpsertGoalsByCsvResponse>
  upsertGoalsByCsvV2?: Maybe<UpsertGoalsByCsvV2Response>
  upsertInvitations: Array<Invitation>
  upsertKpis?: Maybe<Array<Kpi>>
  upsertMyAction?: Maybe<MyActionV2>
  upsertQueryParameterConfigs: Array<Maybe<QueryParameterConfig>>
  upsertReport?: Maybe<Report>
  upsertRoleRecordPermission?: Maybe<RoleRecordPermission>
  upsertStreamSourceRecords: StreamSourceWriteResult
  upsertUserGroups: Array<UserGroup>
  upsertUsersV2: Array<V2User>
  upsertViewGroups?: Maybe<Array<ViewGroup>>
  writeEltChangesChunk: Array<Scalars['EltChangeResultsChunk']>
  writeToSource: Array<HubRecord>
}

export type MutationAddSheetCustomModelPropertyV2Args = {
  input: AddSheetCustomModelPropertyInput
  organizationId: Scalars['ID']
}

export type MutationAdminCreateInvitationArgs = {
  invitation: AdminInvitationCreateInput
}

export type MutationAdminCreateOrganizationArgs = {
  organization: AdminOrganizationInput
}

export type MutationAdminDeleteOrganizationArgs = {
  organizationId: Scalars['ID']
}

export type MutationAdminExecuteScriptArgs = {
  organizationId: Scalars['ID']
  scriptName: AdminExecuteScriptMutationScriptNameEnum
}

export type MutationAdminInvalidateEltModelArgs = {
  id: Scalars['ID']
  type: Scalars['String']
}

export type MutationAdminKillQueueArgs = {
  id: Scalars['ID']
}

export type MutationAdminQueueJobArgs = {
  data: AdminQueueJobInput
}

export type MutationAdminRestoreDeletedOrganizationArgs = {
  organizationId: Scalars['ID']
}

export type MutationAdminRetryQueueArgs = {
  id: Scalars['ID']
}

export type MutationAdminUpdateEltModelFilterArgs = {
  filters: Array<Scalars['String']>
  id: Scalars['ID']
}

export type MutationAdminUpdateInvitationArgs = {
  invitation: AdminInvitationInput
}

export type MutationAdminUpdateOrganizationArgs = {
  organization: AdminOrganizationInput
  organizationId: Scalars['ID']
}

export type MutationAdminUpdateOrganizationFeaturesArgs = {
  features: Array<Scalars['String']>
  organizationId: Scalars['ID']
}

export type MutationAdminUpdateQueueArgs = {
  id: Scalars['ID']
  priority?: InputMaybe<Scalars['Int']>
}

export type MutationAdminUpdateSubscriptionArgs = {
  organizationId: Scalars['ID']
  subscription: SubscriptionInput
}

export type MutationAdminUpdateUserArgs = {
  user: AdminUserInput
}

export type MutationAdminUpsertExtractCallingMetadataJobSettingArgs = {
  organizationId: Scalars['ID']
  setting: AdminExtractCallingMetadataJobSettingInput
}

export type MutationApplyEltModelChangeArgs = {
  eltModelChangeIds: Array<Scalars['ID']>
  organizationId: Scalars['ID']
}

export type MutationArchiveViewArgs = {
  id: Scalars['ID']
  organizationId: Scalars['ID']
}

export type MutationArchiveViewGroupArgs = {
  id: Scalars['ID']
  organizationId: Scalars['ID']
}

export type MutationConvertSalesforceLeadsArgs = {
  inputs: Array<ConvertSalesforceLeadInput>
  organizationId: Scalars['ID']
}

export type MutationCopyViewArgs = {
  id: Scalars['ID']
  isPrivate?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  organizationId: Scalars['ID']
  viewGroupId?: InputMaybe<Scalars['ID']>
}

export type MutationCreateDimensionGroupArgs = {
  dimensionGroup: DimensionGroupCreateInput
  organizationId: Scalars['ID']
}

export type MutationCreateEltModelArgs = {
  eltModel: EltModelCreateInput
  organizationId: Scalars['ID']
}

export type MutationCreateFavoriteArgs = {
  favorite: FavoriteCreateInput
  organizationId: Scalars['ID']
}

export type MutationCreateGoalConfigArgs = {
  goalConfig: GoalConfigCreateInput
  organizationId: Scalars['ID']
}

export type MutationCreateKintoneUserSourceArgs = {
  kintoneId: Scalars['String']
  kintonePassword: Scalars['String']
  organizationId: Scalars['ID']
  sourceId: Scalars['ID']
}

export type MutationCreateOauthI18nSourceArgs = {
  data: OauthI18nSourceInput
}

export type MutationCreateOauthSourceV2Args = {
  data: OauthSourceInput
}

export type MutationCreateOauthUserSourceArgs = {
  data: OauthUserSourceInput
}

export type MutationCreatePolicyUserRoleArgs = {
  organizationId: Scalars['ID']
  policyUserRole: PolicyUserRoleCreateInput
}

export type MutationCreateReverseEltArgs = {
  organizationId: Scalars['ID']
  reverseElt: ReverseEltCreateInput
}

export type MutationCreateSharedUrlArgs = {
  organizationId: Scalars['ID']
  sharedUrl: SharedUrlCreateInput
}

export type MutationCreateSheetThreadCommentArgs = {
  data: SheetThreadCommentCreateInput
}

export type MutationCreateSlackOrgIntegrationArgs = {
  data: SlackOrgIntegrationCreateInput
}

export type MutationCreateSlackUserIntegrationArgs = {
  data: SlackUserIntegrationCreateInput
}

export type MutationCreateSnapshotModelArgs = {
  organizationId: Scalars['ID']
  snapshotModel: SnapshotModelCreateInput
}

export type MutationCreateSourceArgs = {
  data: SourceCreateInput
}

export type MutationCreateViewArgs = {
  input: CreateViewInput
  organizationId: Scalars['ID']
}

export type MutationCreateViewGroupArgs = {
  input: ViewGroupCreateInput
  organizationId: Scalars['ID']
}

export type MutationDebugThrowSampleErrorArgs = {
  errorType: Scalars['String']
}

export type MutationDeleteDimensionGroupArgs = {
  id: Scalars['ID']
  organizationId: Scalars['ID']
}

export type MutationDeleteEltModelArgs = {
  id: Scalars['ID']
  organizationId: Scalars['ID']
}

export type MutationDeleteFavoriteArgs = {
  id: Scalars['ID']
  organizationId: Scalars['ID']
}

export type MutationDeleteGoalConfigArgs = {
  id: Scalars['ID']
  organizationId: Scalars['ID']
}

export type MutationDeleteI18nSourceArgs = {
  id: Scalars['ID']
  organizationId: Scalars['ID']
}

export type MutationDeletePolicyUserRoleArgs = {
  id: Scalars['ID']
  organizationId: Scalars['ID']
}

export type MutationDeleteReverseEltArgs = {
  id: Scalars['ID']
  organizationId: Scalars['ID']
}

export type MutationDeleteSlackOrgIntegrationArgs = {
  data: SlackOrgIntegrationDeleteInput
}

export type MutationDeleteSlackUserIntegrationArgs = {
  data: SlackUserIntegrationDeleteInput
}

export type MutationDeleteSnapshotModelArgs = {
  id: Scalars['ID']
  organizationId: Scalars['ID']
}

export type MutationDeleteSourceArgs = {
  id: Scalars['ID']
  organizationId: Scalars['ID']
}

export type MutationDeleteUserSourceArgs = {
  id: Scalars['ID']
  organizationId: Scalars['ID']
}

export type MutationDeleteViewArgs = {
  id: Scalars['ID']
  ignoreLocked?: InputMaybe<Scalars['Boolean']>
  organizationId: Scalars['ID']
}

export type MutationDeleteViewGroupArgs = {
  id: Scalars['ID']
  organizationId: Scalars['ID']
}

export type MutationInitializeGoalsArgs = {
  dates: Array<Scalars['Date']>
  goalConfigId: Scalars['ID']
  organizationId: Scalars['ID']
}

export type MutationInvalidateEltModelSyncArgs = {
  id: Scalars['ID']
  organizationId: Scalars['ID']
}

export type MutationJoinSlackBotToChannelArgs = {
  data: JoinSlackBotToChannelMutationInput
}

export type MutationMarkSheetThreadCommentNotificationsAsReadArgs = {
  commentIds?: InputMaybe<Array<Scalars['ID']>>
  organizationId: Scalars['ID']
  recordId?: InputMaybe<Scalars['ID']>
}

export type MutationMoveViewGroupsArgs = {
  inputs: Array<MoveViewGroupInput>
  organizationId: Scalars['ID']
}

export type MutationMoveViewsArgs = {
  inputs: Array<MoveViewInput>
  organizationId: Scalars['ID']
}

export type MutationQueueImportSourceUsersQueueArgs = {
  organizationId: Scalars['ID']
}

export type MutationQueueReverseEltJobArgs = {
  organizationId: Scalars['ID']
  reverseEltId: Scalars['ID']
  syncMode: SyncModeEnum
}

export type MutationRecordRenderDurationArgs = {
  organizationId: Scalars['ID']
  renderDurationInput: RenderDurationInput
}

export type MutationReplaceCustomRolePoliciesArgs = {
  customRolePolicies: CustomRolePoliciesInput
  organizationId: Scalars['ID']
}

export type MutationReplaceSubjectPoliciesArgs = {
  organizationId: Scalars['ID']
  subjectPolicies: Array<SubjectPoliciesReplaceInput>
}

export type MutationSyncConnectionArgs = {
  id: Scalars['ID']
  organizationId: Scalars['ID']
  syncContext: SyncContextEnum
}

export type MutationSyncModelsArgs = {
  connectionModels: Array<SyncModelsInput>
  organizationId: Scalars['ID']
  syncContext: SyncContextEnum
}

export type MutationSyncSourceViewArgs = {
  organizationId: Scalars['ID']
}

export type MutationTakeSnapshotArgs = {
  organizationId: Scalars['ID']
  snapshotModelId: Scalars['ID']
  timestamp?: InputMaybe<Scalars['DateTime']>
}

export type MutationUpdateDimensionGroupArgs = {
  dimensionGroup: DimensionGroupUpdateInput
  organizationId: Scalars['ID']
}

export type MutationUpdateEltConfigArgs = {
  eltConfig: EltConfigInput
  organizationId: Scalars['ID']
  sourceId: Scalars['ID']
}

export type MutationUpdateEltModelArgs = {
  eltModel: EltModelUpdateInput
  organizationId: Scalars['ID']
}

export type MutationUpdateGoalConfigArgs = {
  goalConfig: GoalConfigUpdateInput
  organizationId: Scalars['ID']
}

export type MutationUpdateMyUserArgs = {
  myUser: MyUserInput
  organizationId: Scalars['ID']
}

export type MutationUpdateOrganizationV2Args = {
  organization: OrganizationInput
  organizationId: Scalars['ID']
}

export type MutationUpdatePolicyUserRoleArgs = {
  organizationId: Scalars['ID']
  policyUserRole: PolicyUserRoleUpdateInput
}

export type MutationUpdateReverseEltArgs = {
  organizationId: Scalars['ID']
  reverseElt: ReverseEltUpdateInput
}

export type MutationUpdateSheetCustomModelPropertyArgs = {
  input: UpdateSheetCustomModelPropertyInput
  organizationId: Scalars['ID']
}

export type MutationUpdateSheetThreadCommentNotificationArgs = {
  data: SheetThreadCommentNotificationUpdateInput
}

export type MutationUpdateSlackOrgIntegrationArgs = {
  data: SlackOrgIntegrationUpdateInput
}

export type MutationUpdateSlackUserIntegrationArgs = {
  data: SlackUserIntegrationUpdateInput
}

export type MutationUpdateSourceArgs = {
  data: SourceUpdateInput
}

export type MutationUpdateUserGroupByCsvArgs = {
  csv: Scalars['String']
  organizationId: Scalars['ID']
}

export type MutationUpdateUserGroupIdsByCsvArgs = {
  csv: Scalars['String']
  organizationId: Scalars['ID']
}

export type MutationUpdateViewArgs = {
  input: ViewUpdateInputSingle
  organizationId: Scalars['ID']
}

export type MutationUpdateViewGroupsArgs = {
  inputs: Array<ViewGroupUpdateInput>
  organizationId: Scalars['ID']
}

export type MutationUpdateViewsArgs = {
  inputs: Array<ViewUpdateInput>
  organizationId: Scalars['ID']
}

export type MutationUpsertAllowedIpsArgs = {
  allowedIps: Array<AllowedIpInput>
  organizationId: Scalars['ID']
}

export type MutationUpsertBusinessDayArgs = {
  businessDay: BusinessDayInput
  organizationId: Scalars['ID']
}

export type MutationUpsertConnectionArgs = {
  data: ConnectionUpsertInput
}

export type MutationUpsertDashboardItemV2Args = {
  dashboardItem: DashboardItemInput
  organizationId: Scalars['ID']
}

export type MutationUpsertGoalDimensionArgs = {
  goalDimensions: Array<GoalDimensionInput>
  organizationId: Scalars['ID']
}

export type MutationUpsertGoalsByCsvArgs = {
  csv: Scalars['String']
  organizationId: Scalars['ID']
}

export type MutationUpsertGoalsByCsvV2Args = {
  csv: Scalars['String']
  goalConfigId: Scalars['ID']
  organizationId: Scalars['ID']
}

export type MutationUpsertInvitationsArgs = {
  invitations: Array<InvitationInput>
  organizationId: Scalars['ID']
}

export type MutationUpsertKpisArgs = {
  kpis: Array<KpiInput>
  organizationId: Scalars['ID']
}

export type MutationUpsertMyActionArgs = {
  myAction: MyActionInput
  organizationId: Scalars['ID']
}

export type MutationUpsertQueryParameterConfigsArgs = {
  organizationId: Scalars['ID']
  queryParameterConfigs: Array<QueryParameterConfigInput>
}

export type MutationUpsertReportArgs = {
  organizationId: Scalars['ID']
  report: ReportInput
}

export type MutationUpsertRoleRecordPermissionArgs = {
  organizationId: Scalars['ID']
  roleRecordPermission: RoleRecordPermissionInput
}

export type MutationUpsertStreamSourceRecordsArgs = {
  organizationId: Scalars['ID']
  records: Array<StreamSourceRecordsInput>
}

export type MutationUpsertUserGroupsArgs = {
  organizationId: Scalars['ID']
  userGroups: Array<UserGroupInput>
}

export type MutationUpsertUsersV2Args = {
  organizationId: Scalars['ID']
  users: Array<UserInputV2>
}

export type MutationUpsertViewGroupsArgs = {
  organizationId: Scalars['ID']
  viewGroups: Array<ViewGroupInput>
}

export type MutationWriteEltChangesChunkArgs = {
  changesChunks: Array<Scalars['EltChangesChunk']>
  organizationId: Scalars['ID']
  viewId?: InputMaybe<Scalars['ID']>
}

export type MutationWriteToSourceArgs = {
  data: WriteToSourceInput
}

export type MyActionInput = {
  body?: InputMaybe<Scalars['String']>
  chance_count: Scalars['Int']
  count: Scalars['Int']
  deleted_at?: InputMaybe<Scalars['Date']>
  end_at: Scalars['Date']
  id?: InputMaybe<Scalars['ID']>
  kpi_id: Scalars['String']
  name: Scalars['String']
  review?: InputMaybe<Scalars['String']>
  start_at: Scalars['Date']
  status: Scalars['String']
}

export const MyActionStatusEnumV2 = {
  Doing: 'doing',
  Failure: 'failure',
  NoEffect: 'no_effect',
  Review: 'review',
  Success: 'success',
} as const

export type MyActionStatusEnumV2 = (typeof MyActionStatusEnumV2)[keyof typeof MyActionStatusEnumV2]
export type MyActionV2 = {
  __typename?: 'MyActionV2'
  body?: Maybe<Scalars['String']>
  chance_count: Scalars['Int']
  count: Scalars['Int']
  end_at: Scalars['Date']
  id: Scalars['ID']
  kpi?: Maybe<Kpi>
  kpi_id?: Maybe<Scalars['String']>
  name: Scalars['String']
  review?: Maybe<Scalars['String']>
  start_at: Scalars['Date']
  status: MyActionStatusEnumV2
  user?: Maybe<ResourceUser>
  user_id?: Maybe<Scalars['String']>
}

export type MyIdentity = {
  __typename?: 'MyIdentity'
  email: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  users: Array<MyUser>
}

export type MyUser = {
  __typename?: 'MyUser'
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  identity: MyIdentity
  identityId: Scalars['String']
  imageUrl?: Maybe<Scalars['String']>
  lang?: Maybe<LangEnum>
  name: Scalars['String']
  organization: V2Organization
  plan: UserPlanEnum
  planForSync: UserPlanEnum
  planForVis: UserPlanEnum
  resourceUser?: Maybe<ResourceUser>
  role: Scalars['String']
}

export type MyUserInput = {
  imageUrl?: InputMaybe<Scalars['String']>
  lang?: InputMaybe<LangEnum>
  name?: InputMaybe<Scalars['String']>
}

export type NodePropertyNameInput = {
  modelName: Scalars['String']
  nodeName: Scalars['String']
  propertyName: Scalars['String']
}

export const NotificationStatusEnum = {
  NotRead: 'notRead',
  Read: 'read',
} as const

export type NotificationStatusEnum = (typeof NotificationStatusEnum)[keyof typeof NotificationStatusEnum]
export const NotificationSubscriptionModeEnum = {
  All: 'all',
  Mention: 'mention',
  Off: 'off',
} as const

export type NotificationSubscriptionModeEnum =
  (typeof NotificationSubscriptionModeEnum)[keyof typeof NotificationSubscriptionModeEnum]
export type OauthI18nSourceInput = {
  code: Scalars['String']
  lang: LangEnum
  name?: InputMaybe<Scalars['String']>
  organizationId: Scalars['String']
  sourceId: Scalars['String']
  state: Scalars['String']
}

export type OauthSourceInput = {
  clientId?: InputMaybe<Scalars['String']>
  clientSecret?: InputMaybe<Scalars['String']>
  code: Scalars['String']
  id?: InputMaybe<Scalars['ID']>
  name?: InputMaybe<Scalars['String']>
  organizationId: Scalars['String']
  provider: SourceProviderEnum
  sourceConfig?: InputMaybe<Scalars['JSON']>
  state: Scalars['String']
  tokenUrl?: InputMaybe<Scalars['String']>
}

export type OauthUserSourceInput = {
  clientId?: InputMaybe<Scalars['String']>
  clientSecret?: InputMaybe<Scalars['String']>
  code: Scalars['String']
  name?: InputMaybe<Scalars['String']>
  organizationId: Scalars['String']
  provider: SourceProviderEnum
  sourceConfig?: InputMaybe<Scalars['JSON']>
  sourceId: Scalars['String']
  state: Scalars['String']
  tokenUrl?: InputMaybe<Scalars['String']>
}

export type OrganizationCreateInput = {
  id: Scalars['ID']
  imageUrl?: InputMaybe<Scalars['String']>
  name: Scalars['String']
}

export type OrganizationFeature = {
  __typename?: 'OrganizationFeature'
  feature: Scalars['String']
  id: Scalars['ID']
}

export type OrganizationInput = {
  imageUrl?: InputMaybe<Scalars['String']>
  setting?: InputMaybe<OrganizationSettingInput>
}

export type OrganizationSetting = {
  __typename?: 'OrganizationSetting'
  accountClosingMonth: Scalars['Int']
  csvDownloadEnabledForMember: Scalars['Boolean']
  defaultOpenViewIds: Array<Scalars['String']>
  disableEditUsername: Scalars['Boolean']
  disableOldVisualize: Scalars['Boolean']
  i18nEnabled: Scalars['Boolean']
  id: Scalars['ID']
  miitelAccessKey?: Maybe<Scalars['String']>
  miitelCompanyId?: Maybe<Scalars['String']>
  monthBeginningDay: Scalars['Int']
  shouldCreateSalesforceOpportunityWhenConvertingLead: Scalars['Boolean']
  shouldIgnoreInactiveSourceUser: Scalars['Boolean']
  shouldJoinRecordsInApplication: Scalars['Boolean']
  shouldWarmUpCache: Scalars['Boolean']
  userGroupCategoryNames: Array<Scalars['String']>
  viewSiderVisibility: Scalars['Boolean']
}

export type OrganizationSettingInput = {
  accountClosingMonth?: InputMaybe<Scalars['Int']>
  csvDownloadEnabledForMember?: InputMaybe<Scalars['Boolean']>
  defaultOpenViewIds?: InputMaybe<Array<Scalars['String']>>
  disableEditUsername?: InputMaybe<Scalars['Boolean']>
  i18nEnabled?: InputMaybe<Scalars['Boolean']>
  monthBeginningDay?: InputMaybe<Scalars['Int']>
  shouldCreateSalesforceOpportunityWhenConvertingLead?: InputMaybe<Scalars['Boolean']>
  shouldIgnoreInactiveSourceUser?: InputMaybe<Scalars['Boolean']>
  shouldJoinRecordsInApplication?: InputMaybe<Scalars['Boolean']>
  shouldWarmUpCache?: InputMaybe<Scalars['Boolean']>
  userGroupCategoryNames?: InputMaybe<Array<Scalars['String']>>
  viewSiderVisibility?: InputMaybe<Scalars['Boolean']>
}

export const OrganizationStatusEnum = {
  Demo: 'demo',
  Free: 'free',
  Onboarding: 'onboarding',
  Suspended: 'suspended',
  Trial: 'trial',
  Using: 'using',
} as const

export type OrganizationStatusEnum = (typeof OrganizationStatusEnum)[keyof typeof OrganizationStatusEnum]
export type Period = {
  __typename?: 'Period'
  endAt?: Maybe<Scalars['Date']>
  name: Scalars['String']
  startAt?: Maybe<Scalars['Date']>
}

export type PeriodInput = {
  endAt?: InputMaybe<Scalars['Date']>
  name: Scalars['String']
  startAt?: InputMaybe<Scalars['Date']>
}

export const PermissionActionEnum = {
  Full: 'full',
  None: 'none',
  Read: 'read',
} as const

export type PermissionActionEnum = (typeof PermissionActionEnum)[keyof typeof PermissionActionEnum]
export type Policy = {
  __typename?: 'Policy'
  action: Scalars['PolicyAction']
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  permission: Scalars['Boolean']
  principal: Scalars['PolicyPrincipal']
  subject?: Maybe<Scalars['PolicySubject']>
}

export type PolicyUserRole = {
  __typename?: 'PolicyUserRole'
  createdAt?: Maybe<Scalars['DateTime']>
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  label?: Maybe<Scalars['String']>
  name: Scalars['String']
  organizationId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['String']>
}

export type PolicyUserRoleCreateInput = {
  description: Scalars['String']
  label: Scalars['String']
}

export type PolicyUserRoleUpdateInput = {
  description: Scalars['String']
  id: Scalars['String']
  label: Scalars['String']
}

export type PostgresQueryResult = {
  __typename?: 'PostgresQueryResult'
  fields?: Maybe<Array<PostgresQueryResultField>>
  rowCount?: Maybe<Scalars['Int']>
  rows?: Maybe<Scalars['JSON']>
}

export type PostgresQueryResultField = {
  __typename?: 'PostgresQueryResultField'
  name: Scalars['String']
}

export type PostgresStats = {
  __typename?: 'PostgresStats'
  application_name?: Maybe<Scalars['String']>
  backend_start?: Maybe<Scalars['String']>
  backend_type?: Maybe<Scalars['String']>
  backend_xid?: Maybe<Scalars['String']>
  backend_xmin?: Maybe<Scalars['String']>
  client_addr?: Maybe<Scalars['String']>
  client_hostname?: Maybe<Scalars['String']>
  client_port?: Maybe<Scalars['String']>
  datid?: Maybe<Scalars['String']>
  datname?: Maybe<Scalars['String']>
  leader_pid?: Maybe<Scalars['String']>
  pid?: Maybe<Scalars['String']>
  query?: Maybe<Scalars['String']>
  query_start?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  state_change?: Maybe<Scalars['String']>
  usename?: Maybe<Scalars['String']>
  usesysid?: Maybe<Scalars['String']>
  wait_event?: Maybe<Scalars['String']>
  wait_event_type?: Maybe<Scalars['String']>
  xact_start?: Maybe<Scalars['String']>
}

export type PostgresStatus = {
  __typename?: 'PostgresStatus'
  datetime: Scalars['DateTime']
  stats: Array<PostgresStats>
}

export type PostgresTableColumn = {
  __typename?: 'PostgresTableColumn'
  column_label?: Maybe<Scalars['String']>
  column_name: Scalars['String']
  data_type: Scalars['String']
  table_label?: Maybe<Scalars['String']>
  table_name: Scalars['String']
  table_schema: Scalars['String']
}

export type PostrgesQueryResult = {
  __typename?: 'PostrgesQueryResult'
  fields?: Maybe<Array<PostgresQueryResultField>>
  rowCount?: Maybe<Scalars['Int']>
  rows?: Maybe<Scalars['JSON']>
}

export type Property = {
  __typename?: 'Property'
  customized?: Maybe<Scalars['Boolean']>
  destination: PropertyDestination
  meta: PropertyMeta
  name: Scalars['String']
  source?: Maybe<PropertySource>
}

export type PropertyDestination = {
  __typename?: 'PropertyDestination'
  name: Scalars['String']
  sql: Scalars['String']
  type: PropertyTypeEnum
}

export type PropertyDestinationInput = {
  name: Scalars['String']
  sql: Scalars['String']
  type: PropertyTypeEnum
}

export type PropertyInput = {
  customized?: InputMaybe<Scalars['Boolean']>
  destination: PropertyDestinationInput
  meta: PropertyMetaInput
  name: Scalars['String']
  source?: InputMaybe<PropertySourceInput>
}

export type PropertyMeta = {
  __typename?: 'PropertyMeta'
  creatable?: Maybe<Scalars['Boolean']>
  label: Scalars['String']
  meta?: Maybe<PropertyMetaEnum>
  referenceTo?: Maybe<Array<Scalars['String']>>
  selectOptions?: Maybe<Array<PropertySelectOption>>
  typeForMigration?: Maybe<PropertyTypeForMigrationEnum>
  updatable?: Maybe<Scalars['Boolean']>
  validations?: Maybe<Array<ValidationRule>>
}

export const PropertyMetaEnum = {
  Address: 'address',
  Currency: 'currency',
  Email: 'email',
  Html: 'html',
  Id: 'id',
  MultiSelect: 'multi_select',
  Name: 'name',
  Percent: 'percent',
  PhoneNumber: 'phone_number',
  PolymorphicType: 'polymorphic_type',
  RecordUrl: 'record_url',
  Relation: 'relation',
  Select: 'select',
  Text: 'text',
  Url: 'url',
} as const

export type PropertyMetaEnum = (typeof PropertyMetaEnum)[keyof typeof PropertyMetaEnum]
export type PropertyMetaInput = {
  creatable?: InputMaybe<Scalars['Boolean']>
  description?: InputMaybe<Scalars['String']>
  label: Scalars['String']
  meta?: InputMaybe<PropertyMetaEnum>
  referenceTo?: InputMaybe<Array<Scalars['String']>>
  selectOptions?: InputMaybe<Array<PropertySelectOptionInput>>
  trackable?: InputMaybe<Scalars['Boolean']>
  typeForMigration?: InputMaybe<PropertyTypeForMigrationEnum>
  updatable?: InputMaybe<Scalars['Boolean']>
  validations?: InputMaybe<Scalars['JSON']>
}

export type PropertyNameWithStreamName = {
  __typename?: 'PropertyNameWithStreamName'
  propertyName: Scalars['String']
  streamName: Scalars['String']
}

export type PropertyReferenceTo = {
  __typename?: 'PropertyReferenceTo'
  key?: Maybe<Scalars['String']>
  modelName: Scalars['String']
}

export type PropertySelectOption = {
  __typename?: 'PropertySelectOption'
  color?: Maybe<Scalars['String']>
  icon?: Maybe<Scalars['String']>
  label: Scalars['String']
  rule?: Maybe<Scalars['ValidationFunction']>
  value: Scalars['String']
}

export type PropertySelectOptionInput = {
  color?: InputMaybe<Scalars['String']>
  icon?: InputMaybe<Scalars['String']>
  label: Scalars['String']
  rule?: InputMaybe<Scalars['JSON']>
  value: Scalars['JSON']
}

export type PropertySource = {
  __typename?: 'PropertySource'
  meta?: Maybe<PropertySourceMeta>
  name: Scalars['String']
}

export type PropertySourceInput = {
  meta?: InputMaybe<PropertySourceMetaInput>
  name: Scalars['String']
}

export type PropertySourceMeta = {
  __typename?: 'PropertySourceMeta'
  meta?: Maybe<Scalars['String']>
  referenceTo?: Maybe<Array<Scalars['String']>>
  type?: Maybe<Scalars['String']>
}

export type PropertySourceMetaInput = {
  meta?: InputMaybe<Scalars['String']>
  referenceTo?: InputMaybe<Array<Scalars['String']>>
  type?: InputMaybe<Scalars['String']>
}

export const PropertyTypeEnum = {
  Array: 'array',
  Boolean: 'boolean',
  Date: 'date',
  Datetime: 'datetime',
  Integer: 'integer',
  Numeric: 'numeric',
  Object: 'object',
  String: 'string',
  Time: 'time',
} as const

export type PropertyTypeEnum = (typeof PropertyTypeEnum)[keyof typeof PropertyTypeEnum]
export const PropertyTypeForMigrationEnum = {
  SelectTypeWithConvertedValue: 'selectTypeWithConvertedValue',
  SelectTypeWithRawValue: 'selectTypeWithRawValue',
} as const

export type PropertyTypeForMigrationEnum =
  (typeof PropertyTypeForMigrationEnum)[keyof typeof PropertyTypeForMigrationEnum]
export type Query = {
  __typename?: 'Query'
  adminConnections: Array<ConnectionForJobQueue>
  adminEltModels: Array<EltModel>
  adminExtractCallingMetadataJobSetting?: Maybe<ExtractCallingMetadataJobSetting>
  adminFeatureFlags: Array<FeatureFlag>
  adminGptResult: GptResult
  adminJobQueue: JobQueue
  adminJobQueueStats: Array<AdminJobQueueStatsCount>
  adminJobQueues: Array<JobQueue>
  adminJobWorkers: Array<JobWorker>
  adminOrganization?: Maybe<AdminOrganization>
  adminOrganizations: Array<AdminOrganization>
  adminPostgresStatus: PostgresStatus
  adminSoqlResult: Array<Scalars['JSON']>
  adminSpeechToTextResult: SpeechToTextResult
  adminSqlResult: PostgresQueryResult
  adminTableColumns: Array<PostgresTableColumn>
  allowedIps: Array<AllowedIp>
  averageRenderDuration: Array<AverageRenderDuration>
  businessDays: Array<BusinessDay>
  connection?: Maybe<Connection>
  connections: Array<Connection>
  convertingSalesforceLeads: ConvertingSalesforceLeadRelated
  crossTabulateQueries: CrossTabulateQueriesResult
  dashboardItemsV2: Array<DashboardItemV2>
  debugThrowSampleError: Scalars['String']
  destinations: Array<Destination>
  dimensionGroups: Array<DimensionGroup>
  eltModels: Array<EltModel>
  favorites: Array<Favorite>
  goalConfigs: Array<GoalConfig>
  goalDimensions: Array<GoalDimension>
  i18nSources: Array<I18nSource>
  invitations: Array<Invitation>
  jobProcessingQueues: Array<JobQueue>
  jobQueue: JobQueue
  jobQueues: Array<JobQueue>
  jobStatuses: Array<JobQueue>
  kpis: Array<Kpi>
  mixedUsers: Array<MixedUser>
  models: Array<Scalars['CoreModel']>
  myActionsV2: Array<MyActionV2>
  myIdentity?: Maybe<MyIdentity>
  myUser: MyUser
  oauthAuthorizationUrl: Scalars['String']
  organization: V2Organization
  policies: Array<Policy>
  policyUserRoles: Array<PolicyUserRole>
  propertiesBoilerplate: Array<Scalars['CoreModelProperty']>
  queryResult: QueryResult
  queryResults: Array<QueryResult>
  recordChangeHistories: Array<RecordChangeHistory>
  reports: Array<Report>
  resourceUsersWithGroup: Array<ResourceUserWithGroup>
  reverseElts: Array<ReverseElt>
  s3SignedUrl: Scalars['String']
  salescoreConnection: Connection
  salescoreJwt: Scalars['String']
  samlConfigs: Array<SamlConfig>
  sharedUrl?: Maybe<SharedUrl>
  sharedUrls: Array<SharedUrl>
  sheetCustomModels: Array<SheetCustomModel>
  sheetThreadCommentNotifications: Array<SheetThreadCommentNotification>
  sheetThreads: Array<SheetThread>
  slackBotJoinedChannel: SlackBotJoinedChannel
  slackChannels: Array<SlackChannel>
  slackOrgIntegration?: Maybe<SlackOrgIntegration>
  slackOrgIntegrationUrl: Scalars['String']
  slackUserIntegration?: Maybe<SlackUserIntegration>
  slackUserIntegrationUrl: Scalars['String']
  source: Source
  sourceEntityNames: Array<SourceEntityName>
  sourceEntitySchemas: Array<SourceEntitySchema>
  sources: Array<Source>
  sourcesTableRecords: Array<SourcesTableRecord>
  subscriptionStatus: Array<SubscriptionStatus>
  syncConnectionResults: Array<Maybe<SyncConnectionResult>>
  syncFailedConnections: Array<Maybe<SyncFailedConnection>>
  syncModelResults: Array<Maybe<SyncModelResult>>
  syncSuccessfulModels: Array<Scalars['String']>
  userActivities: Array<UserActivity>
  userGroups: Array<UserGroup>
  userSources: Array<UserSource>
  users: Array<V2User>
  viewConnections: Array<Connection>
  viewGroups: Array<ViewGroup>
  viewHasHubspot: Scalars['Boolean']
  viewOldestSyncTime?: Maybe<Scalars['DateTime']>
  viewQueryResult: Scalars['ViewQueryResult']
  viewQueryResults: Array<Maybe<Scalars['ViewQueryResult']>>
  viewRelatedModels: Array<ViewRelatedModel>
  viewResultAsCsv: ViewResultAsCsvResponse
  viewSearchSqlResult: PostrgesQueryResult
  viewSearchSqlResultFixed: PostgresQueryResult
  viewSyncResult: ViewSyncResult
  views: Array<View>
  viewsForSider: Array<ViewForSider>
}

export type QueryAdminEltModelsArgs = {
  organizationId: Scalars['ID']
}

export type QueryAdminExtractCallingMetadataJobSettingArgs = {
  organizationId: Scalars['ID']
}

export type QueryAdminFeatureFlagsArgs = {
  features: Array<Scalars['String']>
}

export type QueryAdminGptResultArgs = {
  model: Scalars['String']
  prompt: Scalars['JSON']
}

export type QueryAdminJobQueueArgs = {
  id: Scalars['ID']
}

export type QueryAdminJobQueuesArgs = {
  pastHours: Scalars['Int']
  status: JobQueueStatusEnum
}

export type QueryAdminOrganizationArgs = {
  organizationId: Scalars['ID']
}

export type QueryAdminOrganizationsArgs = {
  shouldFetchDeleted?: InputMaybe<Scalars['Boolean']>
}

export type QueryAdminSoqlResultArgs = {
  enableQueryAll: Scalars['Boolean']
  organizationId: Scalars['ID']
  query: Scalars['String']
}

export type QueryAdminSpeechToTextResultArgs = {
  prompt: Scalars['String']
  s3ObjectPath: Scalars['String']
}

export type QueryAdminSqlResultArgs = {
  destinationId?: InputMaybe<Scalars['ID']>
  organizationId: Scalars['ID']
  sql: Scalars['String']
}

export type QueryAdminTableColumnsArgs = {
  organizationId: Scalars['ID']
}

export type QueryAllowedIpsArgs = {
  organizationId: Scalars['ID']
}

export type QueryAverageRenderDurationArgs = {
  organizationId: Scalars['ID']
  viewId: Scalars['ID']
}

export type QueryBusinessDaysArgs = {
  organizationId: Scalars['ID']
}

export type QueryConnectionArgs = {
  organizationId: Scalars['ID']
  sourceId: Scalars['ID']
}

export type QueryConnectionsArgs = {
  organizationId: Scalars['ID']
}

export type QueryConvertingSalesforceLeadsArgs = {
  leadIds: Array<Scalars['String']>
  organizationId: Scalars['ID']
}

export type QueryCrossTabulateQueriesArgs = {
  crossTabulationConfig: Scalars['JSON']
  organizationId: Scalars['ID']
  queryModels: Scalars['JSON']
  queryParameter: Scalars['JSON']
}

export type QueryDashboardItemsV2Args = {
  organizationId: Scalars['ID']
}

export type QueryDebugThrowSampleErrorArgs = {
  errorType?: InputMaybe<Scalars['String']>
}

export type QueryDestinationsArgs = {
  organizationId: Scalars['ID']
}

export type QueryDimensionGroupsArgs = {
  organizationId: Scalars['ID']
}

export type QueryEltModelsArgs = {
  lang?: InputMaybe<LangEnum>
  organizationId: Scalars['ID']
}

export type QueryFavoritesArgs = {
  organizationId: Scalars['ID']
}

export type QueryGoalConfigsArgs = {
  organizationId: Scalars['ID']
  viewId?: InputMaybe<Scalars['ID']>
}

export type QueryGoalDimensionsArgs = {
  organizationId: Scalars['ID']
}

export type QueryI18nSourcesArgs = {
  organizationId: Scalars['ID']
}

export type QueryInvitationsArgs = {
  organizationId: Scalars['ID']
}

export type QueryJobProcessingQueuesArgs = {
  ids: Array<Scalars['ID']>
  organizationId: Scalars['ID']
}

export type QueryJobQueueArgs = {
  id: Scalars['ID']
  organizationId: Scalars['ID']
}

export type QueryJobQueuesArgs = {
  jobName?: InputMaybe<Scalars['String']>
  organizationId: Scalars['ID']
}

export type QueryJobStatusesArgs = {
  id?: InputMaybe<Scalars['ID']>
  jobName?: InputMaybe<Scalars['String']>
  organizationId: Scalars['ID']
}

export type QueryKpisArgs = {
  organizationId: Scalars['ID']
}

export type QueryMixedUsersArgs = {
  organizationId: Scalars['ID']
}

export type QueryModelsArgs = {
  organizationId: Scalars['ID']
}

export type QueryMyActionsV2Args = {
  organizationId: Scalars['ID']
}

export type QueryMyUserArgs = {
  organizationId: Scalars['ID']
}

export type QueryOauthAuthorizationUrlArgs = {
  additionalScope?: InputMaybe<Scalars['String']>
  authorizeUrl?: InputMaybe<Scalars['String']>
  clientId?: InputMaybe<Scalars['String']>
  organizationId?: InputMaybe<Scalars['ID']>
  provider: Scalars['String']
  sourceId?: InputMaybe<Scalars['String']>
}

export type QueryOrganizationArgs = {
  organizationId: Scalars['ID']
}

export type QueryPoliciesArgs = {
  action?: InputMaybe<Scalars['String']>
  organizationId: Scalars['ID']
  subjectId?: InputMaybe<Scalars['String']>
  subjectType?: InputMaybe<Scalars['String']>
}

export type QueryPolicyUserRolesArgs = {
  organizationId: Scalars['ID']
}

export type QueryPropertiesBoilerplateArgs = {
  organizationId: Scalars['ID']
  shouldResolveId: Scalars['Boolean']
  sql: Scalars['String']
}

export type QueryQueryResultArgs = {
  organizationId: Scalars['ID']
  query: Scalars['JSON']
}

export type QueryQueryResultsArgs = {
  organizationId: Scalars['ID']
  queries: Scalars['JSON']
}

export type QueryRecordChangeHistoriesArgs = {
  modelName: Scalars['String']
  organizationId: Scalars['ID']
  propertyNames: Array<Scalars['String']>
  recordId: Scalars['String']
}

export type QueryReportsArgs = {
  organizationId: Scalars['ID']
}

export type QueryResourceUsersWithGroupArgs = {
  organizationId: Scalars['ID']
}

export type QueryReverseEltsArgs = {
  organizationId: Scalars['ID']
}

export type QueryS3SignedUrlArgs = {
  organizationId: Scalars['ID']
  s3ObjectPath: Scalars['String']
}

export type QuerySalescoreConnectionArgs = {
  organizationId: Scalars['ID']
}

export type QuerySalescoreJwtArgs = {
  instanceUrl?: InputMaybe<Scalars['String']>
  oauthCode?: InputMaybe<Scalars['String']>
  provider?: InputMaybe<Scalars['String']>
  sessionToken?: InputMaybe<Scalars['String']>
  type: Scalars['String']
}

export type QuerySamlConfigsArgs = {
  organizationId: Scalars['ID']
}

export type QuerySharedUrlArgs = {
  id: Scalars['ID']
  organizationId: Scalars['ID']
}

export type QuerySharedUrlsArgs = {
  organizationId: Scalars['ID']
  viewId: Scalars['String']
}

export type QuerySheetCustomModelsArgs = {
  organizationId: Scalars['ID']
  viewId: Scalars['String']
}

export type QuerySheetThreadCommentNotificationsArgs = {
  organizationId: Scalars['ID']
  page?: InputMaybe<Scalars['Int']>
}

export type QuerySheetThreadsArgs = {
  organizationId: Scalars['ID']
  recordIds: Array<Scalars['ID']>
}

export type QuerySlackBotJoinedChannelArgs = {
  channelId?: InputMaybe<Scalars['ID']>
  organizationId: Scalars['ID']
}

export type QuerySlackChannelsArgs = {
  organizationId: Scalars['ID']
}

export type QuerySlackOrgIntegrationArgs = {
  organizationId: Scalars['ID']
}

export type QuerySlackOrgIntegrationUrlArgs = {
  organizationId: Scalars['ID']
}

export type QuerySlackUserIntegrationArgs = {
  organizationId: Scalars['ID']
}

export type QuerySlackUserIntegrationUrlArgs = {
  organizationId: Scalars['ID']
}

export type QuerySourceArgs = {
  id: Scalars['ID']
  organizationId: Scalars['ID']
}

export type QuerySourceEntityNamesArgs = {
  id: Scalars['ID']
  organizationId: Scalars['ID']
}

export type QuerySourceEntitySchemasArgs = {
  id: Scalars['ID']
  organizationId: Scalars['ID']
  targetEntityNames: Array<Scalars['String']>
}

export type QuerySourcesArgs = {
  organizationId: Scalars['ID']
}

export type QuerySourcesTableRecordsArgs = {
  organizationId: Scalars['ID']
}

export type QuerySubscriptionStatusArgs = {
  organizationId: Scalars['ID']
}

export type QuerySyncConnectionResultsArgs = {
  connectionId: Scalars['ID']
  organizationId: Scalars['ID']
}

export type QuerySyncFailedConnectionsArgs = {
  organizationId: Scalars['ID']
}

export type QuerySyncModelResultsArgs = {
  jobQueueId: Scalars['ID']
  organizationId: Scalars['ID']
}

export type QuerySyncSuccessfulModelsArgs = {
  organizationId: Scalars['ID']
}

export type QueryUserActivitiesArgs = {
  endAt: Scalars['Date']
  organizationId: Scalars['ID']
  startAt: Scalars['Date']
}

export type QueryUserGroupsArgs = {
  organizationId: Scalars['ID']
}

export type QueryUserSourcesArgs = {
  organizationId: Scalars['ID']
}

export type QueryUsersArgs = {
  organizationId: Scalars['ID']
}

export type QueryViewConnectionsArgs = {
  organizationId: Scalars['ID']
  viewId: Scalars['ID']
}

export type QueryViewGroupsArgs = {
  organizationId: Scalars['ID']
}

export type QueryViewHasHubspotArgs = {
  organizationId: Scalars['ID']
  viewId: Scalars['ID']
}

export type QueryViewOldestSyncTimeArgs = {
  organizationId: Scalars['ID']
  viewId: Scalars['ID']
}

export type QueryViewQueryResultArgs = {
  cursor?: InputMaybe<ViewQueryResultCursorInput>
  dateForDiffHighlight?: InputMaybe<Scalars['Date']>
  mustacheParameter: Scalars['JSON']
  organizationId: Scalars['ID']
  shouldJoinRecordsInApplication?: InputMaybe<Scalars['Boolean']>
  viewId: Scalars['ID']
  viewQuery: Scalars['ViewQuery']
}

export type QueryViewQueryResultsArgs = {
  cursor?: InputMaybe<ViewQueryResultCursorInput>
  dateForDiffHighlight?: InputMaybe<Scalars['Date']>
  mustacheParameter: Scalars['JSON']
  organizationId: Scalars['ID']
  viewId?: InputMaybe<Scalars['ID']>
  viewQueries: Array<Scalars['ViewQuery']>
}

export type QueryViewRelatedModelsArgs = {
  organizationId: Scalars['ID']
  viewId: Scalars['ID']
}

export type QueryViewResultAsCsvArgs = {
  mustacheParameter: Scalars['JSON']
  organizationId: Scalars['ID']
  viewId?: InputMaybe<Scalars['ID']>
  viewQuery: Scalars['ViewQuery']
  viewUi: Scalars['ViewUI']
}

export type QueryViewSearchSqlResultArgs = {
  organizationId: Scalars['ID']
  sql: Scalars['String']
}

export type QueryViewSearchSqlResultFixedArgs = {
  organizationId: Scalars['ID']
  sql: Scalars['String']
}

export type QueryViewSyncResultArgs = {
  organizationId: Scalars['ID']
  viewId: Scalars['ID']
}

export type QueryViewsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>
  modelName?: InputMaybe<Scalars['String']>
  organizationId: Scalars['ID']
  type?: InputMaybe<ViewConfigTypeEnum>
  withDependency?: InputMaybe<Scalars['Boolean']>
  withReverseDependency?: InputMaybe<Scalars['Boolean']>
}

export type QueryViewsForSiderArgs = {
  organizationId: Scalars['ID']
}

export type QueryParameterConfig = {
  __typename?: 'QueryParameterConfig'
  columns: Array<Scalars['String']>
  dashboardItemId: Scalars['String']
  id: Scalars['ID']
  invisibleDimensions: Array<Scalars['String']>
  name: Scalars['String']
  period?: Maybe<Period>
  rank: Scalars['Int']
  rows: Array<Scalars['String']>
}

export type QueryParameterConfigInput = {
  columns: Array<Scalars['String']>
  dashboardItemId: Scalars['String']
  deleted?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['ID']>
  invisibleDimensions: Array<Scalars['String']>
  name: Scalars['String']
  period?: InputMaybe<PeriodInput>
  rank: Scalars['Int']
  rows: Array<Scalars['String']>
}

export type QueryResult = {
  __typename?: 'QueryResult'
  error?: Maybe<Scalars['String']>
  fieldLabels?: Maybe<Array<Maybe<Scalars['String']>>>
  query: Scalars['JSON']
  result?: Maybe<PostgresQueryResult>
  sql?: Maybe<Scalars['String']>
}

export type RecordChangeHistory = {
  __typename?: 'RecordChangeHistory'
  changedAt: Scalars['DateTime']
  changedById: Scalars['String']
  changedByName?: Maybe<Scalars['String']>
  newValue?: Maybe<Scalars['String']>
  oldValue?: Maybe<Scalars['String']>
  propertyName: Scalars['String']
}

export type RenderDuration = {
  __typename?: 'RenderDuration'
  createdAt: Scalars['DateTime']
  duration: Scalars['Int']
  id: Scalars['ID']
  isRecordsCached?: Maybe<Scalars['Boolean']>
  organizationId: Scalars['String']
  trigger: RenderTriggerEnum
  updatedAt: Scalars['DateTime']
  viewId: Scalars['String']
  viewType: ViewConfigTypeEnum
}

export type RenderDurationInput = {
  duration: Scalars['Int']
  isRecordsCached?: InputMaybe<Scalars['Boolean']>
  trigger: RenderTriggerEnum
  viewId: Scalars['String']
  viewType: ViewConfigTypeEnum
}

export const RenderTriggerEnum = {
  DrillDownModalOpened: 'drillDownModalOpened',
  KpiPreviewUpdated: 'kpiPreviewUpdated',
  TabChanged: 'tabChanged',
} as const

export type RenderTriggerEnum = (typeof RenderTriggerEnum)[keyof typeof RenderTriggerEnum]
export type Report = {
  __typename?: 'Report'
  id: Scalars['ID']
  kpi?: Maybe<Kpi>
  kpiId?: Maybe<Scalars['String']>
  name: Scalars['String']
  queryModel: Scalars['JSON']
}

export type ReportInput = {
  deleted?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['ID']>
  kpiId?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  queryModel: Scalars['JSON']
}

export type ResourceUser = {
  __typename?: 'ResourceUser'
  big_query_user_id?: Maybe<Scalars['String']>
  comdesk_user_id?: Maybe<Scalars['String']>
  email: Scalars['String']
  google_sheets_user_id?: Maybe<Scalars['String']>
  hubspot_owner_id?: Maybe<Scalars['String']>
  hubspot_user_id?: Maybe<Scalars['String']>
  id: Scalars['ID']
  image_url?: Maybe<Scalars['String']>
  microsoft_dynamics_user_id?: Maybe<Scalars['String']>
  name: Scalars['String']
  next_sfa_user_id?: Maybe<Scalars['String']>
  rank: Scalars['Int']
  salescore_invitation_id?: Maybe<Scalars['String']>
  salescore_user_id?: Maybe<Scalars['String']>
  salesforce_user_id?: Maybe<Scalars['String']>
  senses_user_id?: Maybe<Scalars['String']>
  user_group_id?: Maybe<Scalars['String']>
  visibility: Scalars['Boolean']
  zoho_user_id?: Maybe<Scalars['String']>
}

export type ResourceUserInput = {
  id: Scalars['ID']
  imageUrl?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  visibility?: InputMaybe<Scalars['Boolean']>
}

export type ResourceUserWithGroup = {
  __typename?: 'ResourceUserWithGroup'
  email: Scalars['String']
  id: Scalars['ID']
  image_url?: Maybe<Scalars['String']>
  name: Scalars['String']
  rank: Scalars['Int']
  user_group_d1_id?: Maybe<Scalars['String']>
  user_group_d1_name?: Maybe<Scalars['String']>
  user_group_d2_id?: Maybe<Scalars['String']>
  user_group_d2_name?: Maybe<Scalars['String']>
  user_group_d3_id?: Maybe<Scalars['String']>
  user_group_d3_name?: Maybe<Scalars['String']>
  user_group_d4_id?: Maybe<Scalars['String']>
  user_group_d4_name?: Maybe<Scalars['String']>
  user_group_d5_id?: Maybe<Scalars['String']>
  user_group_d5_name?: Maybe<Scalars['String']>
  user_group_depth?: Maybe<Scalars['Int']>
  user_group_id?: Maybe<Scalars['String']>
  visibility: Scalars['Boolean']
}

export type ReverseElt = {
  __typename?: 'ReverseElt'
  connectionId: Scalars['String']
  destination: Destination
  destinationId: Scalars['String']
  eltModel: EltModel
  eltModelId: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  sql: Scalars['String']
  syncedAt?: Maybe<Scalars['DateTime']>
}

export type ReverseEltCreateInput = {
  destinationId: Scalars['String']
  eltModelId: Scalars['String']
  name: Scalars['String']
  sql: Scalars['String']
}

export type ReverseEltUpdateInput = {
  id: Scalars['ID']
  name: Scalars['String']
  sql: Scalars['String']
}

export type RoleRecordPermission = {
  __typename?: 'RoleRecordPermission'
  action: PermissionActionEnum
  id: Scalars['ID']
  role: Scalars['String']
  viewGroupId?: Maybe<Scalars['String']>
  viewId?: Maybe<Scalars['String']>
}

export type RoleRecordPermissionInput = {
  action: PermissionActionEnum
  deleted?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['ID']>
  permissionableDefaultPermission?: InputMaybe<PermissionActionEnum>
  role: Scalars['String']
  viewGroupId?: InputMaybe<Scalars['String']>
  viewId?: InputMaybe<Scalars['String']>
}

export type SalesforceLeadStatus = {
  __typename?: 'SalesforceLeadStatus'
  id: Scalars['String']
  label: Scalars['String']
  value: Scalars['String']
}

export type SamlConfig = {
  __typename?: 'SamlConfig'
  icon?: Maybe<Scalars['String']>
  id: Scalars['ID']
  label: Scalars['String']
  organizationId: Scalars['String']
}

export type SharedUrl = {
  __typename?: 'SharedUrl'
  createdAt?: Maybe<Scalars['DateTime']>
  createdBy?: Maybe<ViewCreatedBy>
  createdById?: Maybe<Scalars['String']>
  id: Scalars['ID']
  organizationId?: Maybe<Scalars['String']>
  parameter: Scalars['JSON']
  sheetThread?: Maybe<SheetThreadWithoutCommentsAndSharedUrl>
  sheetThreadComment?: Maybe<SheetThreadComment>
  type?: Maybe<ViewConfigTypeEnum>
  viewId?: Maybe<Scalars['String']>
}

export type SharedUrlCreateInput = {
  parameter: Scalars['JSON']
  type: ViewConfigTypeEnum
  viewId: Scalars['String']
}

export type SheetCustomModel = {
  __typename?: 'SheetCustomModel'
  id: Scalars['ID']
  name: Scalars['String']
  nodeName: Scalars['String']
  properties: Array<Scalars['CoreModelProperty']>
  viewId: Scalars['String']
}

export type SheetThread = {
  __typename?: 'SheetThread'
  comments: Array<Maybe<SheetThreadComment>>
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  organizationId: Scalars['String']
  recordId: Scalars['String']
  recordName?: Maybe<Scalars['String']>
  sharedUrl?: Maybe<SharedUrl>
  updatedAt: Scalars['DateTime']
}

export type SheetThreadComment = {
  __typename?: 'SheetThreadComment'
  createdAt: Scalars['DateTime']
  createdBy?: Maybe<V2User>
  id: Scalars['ID']
  message: Scalars['String']
  sharedUrl?: Maybe<SharedUrl>
  sheetThread: SheetThreadWithoutComments
  slackSheetThreadComment?: Maybe<SlackSheetThreadComment>
  updatedAt: Scalars['DateTime']
}

export type SheetThreadCommentCreateInput = {
  additionalConfigFilterLeafs?: InputMaybe<Scalars['JSON']>
  kpiPivotParameter?: InputMaybe<Scalars['JSON']>
  kpiPivotPresetName?: InputMaybe<Scalars['String']>
  message: Scalars['String']
  modelName: Scalars['String']
  organizationId: Scalars['String']
  recordId: Scalars['String']
  recordName?: InputMaybe<Scalars['String']>
  sheetPresetName?: InputMaybe<Scalars['String']>
  sheetViewId?: InputMaybe<Scalars['String']>
  threadId?: InputMaybe<Scalars['String']>
  viewId: Scalars['String']
}

export type SheetThreadCommentNotification = {
  __typename?: 'SheetThreadCommentNotification'
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  sheetThreadComment: SheetThreadComment
  status: NotificationStatusEnum
  updatedAt: Scalars['DateTime']
  user: V2User
}

export type SheetThreadCommentNotificationUpdateInput = {
  organizationId: Scalars['String']
  sheetThreadCommentNotificationId: Scalars['String']
  status: NotificationStatusEnum
}

export type SheetThreadCreateInput = {
  modelName: Scalars['String']
  organizationId: Scalars['String']
  recordId: Scalars['String']
  recordName?: InputMaybe<Scalars['String']>
  viewId: Scalars['String']
}

export type SheetThreadWithoutComments = {
  __typename?: 'SheetThreadWithoutComments'
  id: Scalars['ID']
  modelLabel?: Maybe<Scalars['String']>
  modelName?: Maybe<Scalars['String']>
  organizationId: Scalars['String']
  recordId: Scalars['String']
  recordName?: Maybe<Scalars['String']>
  sharedUrl?: Maybe<SharedUrl>
}

export type SheetThreadWithoutCommentsAndSharedUrl = {
  __typename?: 'SheetThreadWithoutCommentsAndSharedUrl'
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  organizationId: Scalars['String']
  recordId: Scalars['String']
  recordName?: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
}

export type SlackBotJoinedChannel = {
  __typename?: 'SlackBotJoinedChannel'
  status: Scalars['Boolean']
}

export type SlackChannel = {
  __typename?: 'SlackChannel'
  id: Scalars['ID']
  name: Scalars['String']
  numMembers: Scalars['Int']
  purpose?: Maybe<Scalars['String']>
  topic?: Maybe<Scalars['String']>
}

export type SlackOrgIntegration = {
  __typename?: 'SlackOrgIntegration'
  accessToken: Scalars['String']
  accessTokenExpiresAt?: Maybe<Scalars['DateTime']>
  createdAt: Scalars['DateTime']
  defaultChannelId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  integratedBy: V2User
  refreshToken?: Maybe<Scalars['String']>
  slackUserId: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export type SlackOrgIntegrationCreateInput = {
  authCode: Scalars['String']
  organizationId: Scalars['String']
}

export type SlackOrgIntegrationDeleteInput = {
  organizationId: Scalars['String']
}

export type SlackOrgIntegrationUpdateInput = {
  defaultChannelId?: InputMaybe<Scalars['String']>
  organizationId: Scalars['String']
}

export type SlackSheetThreadComment = {
  __typename?: 'SlackSheetThreadComment'
  createdBySlackUserName: Scalars['String']
}

export type SlackUserIntegration = {
  __typename?: 'SlackUserIntegration'
  accessToken: Scalars['String']
  accessTokenExpiresAt?: Maybe<Scalars['DateTime']>
  createdAt: Scalars['DateTime']
  defaultChannelId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  refreshToken?: Maybe<Scalars['String']>
  slackUserId: Scalars['String']
  updatedAt: Scalars['DateTime']
  user: V2User
}

export type SlackUserIntegrationCreateInput = {
  authCode: Scalars['String']
  organizationId: Scalars['String']
}

export type SlackUserIntegrationDeleteInput = {
  organizationId: Scalars['String']
}

export type SlackUserIntegrationUpdateInput = {
  defaultChannelId?: InputMaybe<Scalars['String']>
  organizationId: Scalars['String']
}

export type SnapshotModelCreateInput = {
  model: Scalars['CoreModel']
  name: Scalars['String']
  transform: EltModelTransformInput
}

export type SnapshotSequenceModel = {
  __typename?: 'SnapshotSequenceModel'
  createdAt: Scalars['String']
  id: Scalars['ID']
}

export type Source = {
  __typename?: 'Source'
  config?: Maybe<SourceConfig>
  connection?: Maybe<Connection>
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  isAvailable?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  organizationId: Scalars['String']
  provider: SourceProviderEnum
  updatedAt: Scalars['DateTime']
  writableWithoutUserSource: Scalars['Boolean']
}

export type SourceConfig = {
  __typename?: 'SourceConfig'
  meta?: Maybe<SourceConfigMeta>
  spreadsheetId?: Maybe<Scalars['String']>
}

export type SourceConfigMeta = {
  __typename?: 'SourceConfigMeta'
  display_name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export type SourceCreateInput = {
  config: Scalars['JSON']
  id?: InputMaybe<Scalars['ID']>
  name?: InputMaybe<Scalars['String']>
  organizationId: Scalars['String']
  provider: SourceProviderEnum
}

export type SourceEntityName = {
  __typename?: 'SourceEntityName'
  forcePick?: Maybe<Scalars['Boolean']>
  label: Scalars['String']
  name: Scalars['ID']
}

export type SourceEntityPropertySchema = {
  __typename?: 'SourceEntityPropertySchema'
  forcePick?: Maybe<Scalars['Boolean']>
  label: Scalars['String']
  meta?: Maybe<PropertyMetaEnum>
  name: Scalars['ID']
  referenceTo?: Maybe<Array<Scalars['String']>>
  selectOptions?: Maybe<Array<ModelPropertySelectOption>>
  type?: Maybe<PropertyTypeEnum>
}

export type SourceEntitySchema = {
  __typename?: 'SourceEntitySchema'
  forcePick?: Maybe<Scalars['Boolean']>
  label: Scalars['String']
  name: Scalars['ID']
  properties: Array<SourceEntityPropertySchema>
}

export const SourceProviderEnum = {
  BigQuery: 'big_query',
  Comdesk: 'comdesk',
  FileMaker: 'file_maker',
  Gmail: 'gmail',
  Google: 'google',
  GoogleCalendar: 'google_calendar',
  GoogleSheets: 'google_sheets',
  Hubspot: 'hubspot',
  Kintone: 'kintone',
  MicrosoftDynamics: 'microsoft_dynamics',
  NextSfa: 'next_sfa',
  Postgres: 'postgres',
  Salescore: 'salescore',
  Salesforce: 'salesforce',
  Senses: 'senses',
  Zoho: 'zoho',
  Zoom: 'zoom',
} as const

export type SourceProviderEnum = (typeof SourceProviderEnum)[keyof typeof SourceProviderEnum]
export type SourceUpdateInput = {
  config?: InputMaybe<Scalars['JSON']>
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
  organizationId: Scalars['String']
}

export type SourcesTableRecord = {
  __typename?: 'SourcesTableRecord'
  connection: Connection
  hasError: Scalars['Boolean']
  lastSyncedAt?: Maybe<Scalars['DateTime']>
}

export type SpeechToTextResult = {
  __typename?: 'SpeechToTextResult'
  s3SignedUrl: Scalars['String']
  transcript: Scalars['String']
}

export type Stream = {
  __typename?: 'Stream'
  connectionId?: Maybe<Scalars['String']>
  customized?: Maybe<Scalars['Boolean']>
  destination: StreamDestination
  filtered?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['ID']>
  meta: StreamMeta
  name: Scalars['String']
  properties: Array<Property>
  source?: Maybe<StreamSource>
}

export type StreamDestination = {
  __typename?: 'StreamDestination'
  derivedTableDefinition?: Maybe<StreamDestinationDerivedTableDefinition>
  filters?: Maybe<Array<Scalars['String']>>
  indices?: Maybe<Array<StreamDestinationIndex>>
  materialized?: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  viewDefinitionSQL?: Maybe<Scalars['String']>
}

export type StreamDestinationDerivedTableDefinition = {
  __typename?: 'StreamDestinationDerivedTableDefinition'
  deleteKey: Scalars['String']
  deleteSql: Scalars['String']
  dependentStreams: Array<DependentStream>
  primaryKey?: Maybe<Scalars['String']>
  sql: Scalars['String']
}

export type StreamDestinationDerivedTableDefinitionInput = {
  deleteKey: Scalars['String']
  deleteSql: Scalars['String']
  dependentStreams: Array<DependentStreamInput>
  primaryKey?: InputMaybe<Scalars['String']>
  sql: Scalars['String']
}

export type StreamDestinationIndex = {
  __typename?: 'StreamDestinationIndex'
  name: Scalars['String']
  propertyNames: Array<Scalars['String']>
}

export type StreamDestinationIndexInput = {
  name: Scalars['String']
  propertyNames: Array<Scalars['String']>
}

export type StreamDestinationInput = {
  derivedTableDefinition?: InputMaybe<StreamDestinationDerivedTableDefinitionInput>
  filters?: InputMaybe<Array<Scalars['String']>>
  indices?: InputMaybe<Array<StreamDestinationIndexInput>>
  materialized?: InputMaybe<Scalars['Boolean']>
  name: Scalars['String']
  viewDefinitionSQL?: InputMaybe<Scalars['String']>
}

export type StreamInput = {
  customized?: InputMaybe<Scalars['Boolean']>
  destination: StreamDestinationInput
  filtered?: InputMaybe<Scalars['Boolean']>
  meta: StreamMetaInput
  name: Scalars['String']
  properties: Array<PropertyInput>
  source?: InputMaybe<StreamSourceInput>
}

export type StreamMeta = {
  __typename?: 'StreamMeta'
  creatable?: Maybe<Scalars['Boolean']>
  deletable?: Maybe<Scalars['Boolean']>
  label: Scalars['String']
  updatable?: Maybe<Scalars['Boolean']>
}

export type StreamMetaInput = {
  creatable?: InputMaybe<Scalars['Boolean']>
  deletable?: InputMaybe<Scalars['Boolean']>
  label: Scalars['String']
  updatable?: InputMaybe<Scalars['Boolean']>
}

export type StreamSource = {
  __typename?: 'StreamSource'
  filters?: Maybe<Array<Scalars['String']>>
  name: Scalars['String']
}

export type StreamSourceInput = {
  filters?: InputMaybe<Array<Scalars['String']>>
  name: Scalars['String']
}

export type StreamSourceRecordsInput = {
  after?: InputMaybe<Scalars['JSON']>
  before?: InputMaybe<Scalars['JSON']>
  deleted?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['String']>
  recordNameLabel?: InputMaybe<Scalars['String']>
  streamName: Scalars['String']
}

export type StreamSourceWriteResult = {
  __typename?: 'StreamSourceWriteResult'
  errors: Array<WriteResultError>
}

export type SubjectPoliciesReplaceInput = {
  action: Scalars['PolicyAction']
  principalsWithPermission: Array<SubjectPoliciesReplaceInputPrincipalsWithPermission>
  subject: Scalars['PolicySubject']
}

export type SubjectPoliciesReplaceInputPrincipalsWithPermission = {
  permission: Scalars['Boolean']
  principal: Scalars['PolicyPrincipal']
}

export type Subscription = {
  __typename?: 'Subscription'
  canceledAt?: Maybe<Scalars['DateTime']>
  createdAt: Scalars['DateTime']
  id: Scalars['String']
  note?: Maybe<Scalars['String']>
  numberOfSeats: Scalars['Int']
  organizationId: Scalars['String']
  plan: SubscriptionPlanEnum
  pricePerSeat: Scalars['Int']
}

export type SubscriptionInput = {
  note?: InputMaybe<Scalars['String']>
  numberOfSeats: Scalars['Int']
  plan: SubscriptionPlanEnum
  pricePerSeat: Scalars['Int']
}

export const SubscriptionPlanEnum = {
  Full: 'full',
  Sheet: 'sheet',
  Standard: 'standard',
  Sync: 'sync',
  Visualize: 'visualize',
} as const

export type SubscriptionPlanEnum = (typeof SubscriptionPlanEnum)[keyof typeof SubscriptionPlanEnum]
export type SubscriptionStatus = {
  __typename?: 'SubscriptionStatus'
  isInvitationCreatable: Scalars['Boolean']
  isInvitationFreeOrganization: Scalars['Boolean']
  numberOfFreeInvitations: Scalars['Int']
  numberOfFreeUsers: Scalars['Int']
  numberOfInvitations: Scalars['Int']
  numberOfRestSeats: Scalars['Int']
  numberOfSeats: Scalars['Int']
  numberOfUsers: Scalars['Int']
  subscription: Subscription
}

export type SyncConnectionResult = {
  __typename?: 'SyncConnectionResult'
  checkError?: Maybe<Scalars['String']>
  checkSuccess: Scalars['Boolean']
  createdAt: Scalars['DateTime']
  jobQueueId: Scalars['ID']
  syncSuccess: Scalars['Boolean']
}

export const SyncContextEnum = {
  ManualBulk: 'MANUAL_BULK',
  ManualBulkSoftDelete: 'MANUAL_BULK_SOFT_DELETE',
  ManualIncremental: 'MANUAL_INCREMENTAL',
} as const

export type SyncContextEnum = (typeof SyncContextEnum)[keyof typeof SyncContextEnum]
export type SyncFailedConnection = {
  __typename?: 'SyncFailedConnection'
  connectionId: Scalars['ID']
  jobQueueId: Scalars['ID']
}

export const SyncModeEnum = {
  Bulk: 'BULK',
  Incremental: 'INCREMENTAL',
} as const

export type SyncModeEnum = (typeof SyncModeEnum)[keyof typeof SyncModeEnum]
export type SyncModelResult = {
  __typename?: 'SyncModelResult'
  error?: Maybe<Scalars['String']>
  label: Scalars['String']
  name: Scalars['String']
  success: Scalars['Boolean']
  timestamp: Scalars['DateTime']
}

export type SyncModelsInput = {
  connectionId: Scalars['ID']
  eltModelIds: Array<Scalars['ID']>
}

export type UpdateSheetCustomModelPropertyInput = {
  id: Scalars['ID']
  label: Scalars['String']
  propertyName: Scalars['String']
  selectOptions?: InputMaybe<Scalars['JSON']>
}

export const UpdateViewModeEnum = {
  KpiTimeSeriesUseExistingGoalConfigFragment: 'kpiTimeSeriesUseExistingGoalConfigFragment',
} as const

export type UpdateViewModeEnum = (typeof UpdateViewModeEnum)[keyof typeof UpdateViewModeEnum]
export type UserActivity = {
  __typename?: 'UserActivity'
  lastSignedInAt?: Maybe<Scalars['DateTime']>
  loginCount: Scalars['Int']
  session: Scalars['Int']
  user: V2User
  userId: Scalars['ID']
  viewKpiDaysCount: Scalars['Int']
  viewKpiOrKpiPivotDaysCount: Scalars['Int']
  viewKpiPivotDaysCount: Scalars['Int']
  viewSheetDaysCount: Scalars['Int']
}

export type UserGroup = {
  __typename?: 'UserGroup'
  depth: Scalars['Int']
  id: Scalars['ID']
  image_url?: Maybe<Scalars['String']>
  name: Scalars['String']
  rank: Scalars['Int']
  user_group_id?: Maybe<Scalars['String']>
  users: Array<ResourceUser>
}

export type UserGroupInput = {
  deleted?: InputMaybe<Scalars['Boolean']>
  depth: Scalars['Int']
  id?: InputMaybe<Scalars['ID']>
  image_url?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  rank: Scalars['Int']
  userIds: Array<Scalars['String']>
  user_group_id?: InputMaybe<Scalars['String']>
}

export type UserInput = {
  identityOldId: Scalars['ID']
  imageUrl?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  oldId: Scalars['ID']
  organizationId: Scalars['ID']
  role: Scalars['String']
}

export type UserInputV2 = {
  deleted?: InputMaybe<Scalars['Boolean']>
  id: Scalars['ID']
  imageUrl?: InputMaybe<Scalars['String']>
  role?: InputMaybe<Scalars['String']>
}

export const UserPlanEnum = {
  Free: 'free',
  None: 'none',
  Standard: 'standard',
} as const

export type UserPlanEnum = (typeof UserPlanEnum)[keyof typeof UserPlanEnum]
export const UserRoleEnumV2 = {
  Admin: 'admin',
  Guest: 'guest',
  Manager: 'manager',
  Member: 'member',
  NecMember: 'necMember',
  NecPlayingManager: 'necPlayingManager',
  NxMember: 'nxMember',
  PlayingManager: 'playingManager',
  ReadonlyMember: 'readonlyMember',
} as const

export type UserRoleEnumV2 = (typeof UserRoleEnumV2)[keyof typeof UserRoleEnumV2]
export type UserSource = {
  __typename?: 'UserSource'
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  isAvailable?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  provider: SourceProviderEnum
  updatedAt: Scalars['DateTime']
}

export type V2Organization = {
  __typename?: 'V2Organization'
  businessDays: Array<BusinessDay>
  createdAt: Scalars['DateTime']
  currentPlan: SubscriptionPlanEnum
  dimensionGroups: Array<DimensionGroup>
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  isEarlyReleaseTarget: Scalars['Boolean']
  name: Scalars['String']
  organizationFeatures: Array<OrganizationFeature>
  setting: OrganizationSetting
  status: OrganizationStatusEnum
  updatedAt: Scalars['DateTime']
}

export type V2User = {
  __typename?: 'V2User'
  createdAt: Scalars['DateTime']
  deletedAt?: Maybe<Scalars['DateTime']>
  id: Scalars['ID']
  identity: Identity
  imageUrl?: Maybe<Scalars['String']>
  isManager: Scalars['Boolean']
  name: Scalars['String']
  plan: UserPlanEnum
  planForSync: UserPlanEnum
  planForVis: UserPlanEnum
  role: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export type ValidationRule = {
  __typename?: 'ValidationRule'
  feedbackProperties: Array<PropertyNameWithStreamName>
  function: Scalars['ValidationFunction']
  message: Scalars['String']
}

export type View = {
  __typename?: 'View'
  archived: Scalars['Boolean']
  archivedAt?: Maybe<Scalars['DateTime']>
  config: Scalars['ViewConfig']
  createdAt?: Maybe<Scalars['DateTime']>
  createdBy?: Maybe<ViewCreatedBy>
  createdById?: Maybe<Scalars['String']>
  defaultPermission: PermissionActionEnum
  eltModelId?: Maybe<Scalars['String']>
  emoji?: Maybe<Scalars['String']>
  id: Scalars['ID']
  importedSourceId?: Maybe<Scalars['String']>
  locked: Scalars['Boolean']
  name: Scalars['String']
  private: Scalars['Boolean']
  queries: Array<Scalars['ViewQuery']>
  query?: Maybe<Scalars['ViewQuery']>
  rank: Scalars['Int']
  roleRecordPermissions: Array<RoleRecordPermission>
  type: ViewConfigTypeEnum
  ui: Scalars['ViewUI']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedBy?: Maybe<ViewUpdatedBy>
  updatedById?: Maybe<Scalars['String']>
  viewGroupId?: Maybe<Scalars['String']>
  viewGroupIdBeforeArchived?: Maybe<Scalars['String']>
}

export const ViewConfigTypeEnum = {
  Form: 'form',
  Kpi: 'kpi',
  KpiPivot: 'kpiPivot',
  KpiTimeSeries: 'kpiTimeSeries',
  Sheet: 'sheet',
} as const

export type ViewConfigTypeEnum = (typeof ViewConfigTypeEnum)[keyof typeof ViewConfigTypeEnum]
export type ViewCreatedBy = {
  __typename?: 'ViewCreatedBy'
  id: Scalars['ID']
  name: Scalars['String']
}

export type ViewForSider = {
  __typename?: 'ViewForSider'
  archived: Scalars['Boolean']
  archivedAt?: Maybe<Scalars['DateTime']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdBy?: Maybe<ViewCreatedBy>
  createdById?: Maybe<Scalars['String']>
  defaultPermission: PermissionActionEnum
  eltModelId?: Maybe<Scalars['String']>
  emoji?: Maybe<Scalars['String']>
  id: Scalars['ID']
  importedSourceId?: Maybe<Scalars['String']>
  locked: Scalars['Boolean']
  name: Scalars['String']
  private: Scalars['Boolean']
  rank: Scalars['Int']
  roleRecordPermissions: Array<RoleRecordPermission>
  type: ViewConfigTypeEnum
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedBy?: Maybe<ViewUpdatedBy>
  updatedById?: Maybe<Scalars['String']>
  viewGroupId?: Maybe<Scalars['String']>
  viewGroupIdBeforeArchived?: Maybe<Scalars['String']>
}

export type ViewGroup = {
  __typename?: 'ViewGroup'
  archived: Scalars['Boolean']
  archivedAt?: Maybe<Scalars['DateTime']>
  createdAt: Scalars['DateTime']
  createdBy?: Maybe<ViewCreatedBy>
  createdById?: Maybe<Scalars['String']>
  emoji?: Maybe<Scalars['String']>
  id: Scalars['ID']
  importedSourceId?: Maybe<Scalars['String']>
  name: Scalars['String']
  private: Scalars['Boolean']
  rank: Scalars['Int']
  streamName: Scalars['String']
  type: ViewGroupTypeEnum
  updatedAt: Scalars['DateTime']
  updatedBy?: Maybe<ViewUpdatedBy>
  updatedById?: Maybe<Scalars['String']>
  viewGroupId?: Maybe<Scalars['String']>
  viewGroupIdBeforeArchived?: Maybe<Scalars['String']>
}

export type ViewGroupCreateInput = {
  emoji?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  private?: InputMaybe<Scalars['Boolean']>
  type?: InputMaybe<ViewGroupTypeEnum>
  viewGroupId?: InputMaybe<Scalars['String']>
}

export type ViewGroupInput = {
  deleted?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['ID']>
  name: Scalars['String']
  rank: Scalars['Int']
  streamName?: InputMaybe<Scalars['String']>
  viewGroupId?: InputMaybe<Scalars['String']>
}

export const ViewGroupTypeEnum = {
  Kpi: 'kpi',
  KpiPivot: 'kpiPivot',
  KpiTimeSeries: 'kpiTimeSeries',
  Sheet: 'sheet',
} as const

export type ViewGroupTypeEnum = (typeof ViewGroupTypeEnum)[keyof typeof ViewGroupTypeEnum]
export type ViewGroupUpdateInput = {
  emoji?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
  rank?: InputMaybe<Scalars['Int']>
  viewGroupId?: InputMaybe<Scalars['String']>
}

export type ViewInput = {
  config?: InputMaybe<Scalars['ViewConfig']>
  deleted?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['ID']>
  name?: InputMaybe<Scalars['String']>
  queries?: InputMaybe<Array<Scalars['ViewQuery']>>
  query: Scalars['ViewQuery']
  rank?: InputMaybe<Scalars['Int']>
  ui: Scalars['ViewUI']
  viewGroupId: Scalars['String']
}

export type ViewQueryResultCursorInput = {
  chunkSize: Scalars['Int']
  nextId?: InputMaybe<Scalars['String']>
  page: Scalars['Int']
  pageSize: Scalars['Int']
}

export type ViewRelatedModel = {
  __typename?: 'ViewRelatedModel'
  connection: Connection
  eltModels: Array<EltModel>
}

export type ViewSyncResult = {
  __typename?: 'ViewSyncResult'
  failedEltModels: Array<EltModel>
  success: Scalars['Boolean']
}

export type ViewUpdateInput = {
  emoji?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
  query?: InputMaybe<Scalars['ViewQuery']>
  rank?: InputMaybe<Scalars['Int']>
  ui?: InputMaybe<Scalars['ViewUI']>
  viewGroupId?: InputMaybe<Scalars['String']>
}

export type ViewUpdateInputSingle = {
  config?: InputMaybe<Scalars['ViewConfig']>
  emoji?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  ignoreLocked?: InputMaybe<Scalars['Boolean']>
  locked?: InputMaybe<Scalars['Boolean']>
  mode?: InputMaybe<UpdateViewModeEnum>
  name?: InputMaybe<Scalars['String']>
}

export type ViewUpdatedBy = {
  __typename?: 'ViewUpdatedBy'
  id: Scalars['ID']
  name: Scalars['String']
}

export type WriteResultError = {
  __typename?: 'WriteResultError'
  description?: Maybe<Scalars['String']>
  detail?: Maybe<Scalars['JSON']>
  id?: Maybe<Scalars['String']>
  message: Scalars['String']
  name?: Maybe<Scalars['String']>
  propertySourceNames?: Maybe<Array<Scalars['String']>>
  streamName?: Maybe<Scalars['String']>
}

export type WriteToSourceInput = {
  connectionId: Scalars['ID']
  organizationId: Scalars['ID']
  records: Scalars['JSON']
}

export type AdminJobQueueStatsCount = {
  __typename?: 'adminJobQueueStatsCount'
  count: Scalars['Int']
  status: JobQueueStatusEnum
}

export type UpdateUserGroupByCsvErrorsResponse = {
  __typename?: 'updateUserGroupByCsvErrorsResponse'
  index: Scalars['Int']
  message: Scalars['String']
  record: Scalars['JSON']
}

export type UpdateUserGroupByCsvResponse = {
  __typename?: 'updateUserGroupByCsvResponse'
  errors: Array<UpdateUserGroupByCsvErrorsResponse>
  success: Array<UpdateUserGroupByCsvSuccessResponse>
  successCount: Scalars['Int']
}

export type UpdateUserGroupByCsvSuccessResponse = {
  __typename?: 'updateUserGroupByCsvSuccessResponse'
  index: Scalars['Int']
  record: Scalars['JSON']
}

export type UpdateUserGroupIdByCsvResponse = {
  __typename?: 'updateUserGroupIdByCsvResponse'
  errors: Array<UpdateUserGroupIdsByCsvErrorsResponse>
  success: Array<UpdateUserGroupIdsByCsvSuccessResponse>
  successCount: Scalars['Int']
}

export type UpdateUserGroupIdsByCsvErrorsResponse = {
  __typename?: 'updateUserGroupIdsByCsvErrorsResponse'
  index: Scalars['Int']
  message: Scalars['String']
  record: Scalars['JSON']
}

export type UpdateUserGroupIdsByCsvSuccessResponse = {
  __typename?: 'updateUserGroupIdsByCsvSuccessResponse'
  index: Scalars['Int']
  record: Scalars['JSON']
}

export type UpsertGoalsByCsvErrorsResponse = {
  __typename?: 'upsertGoalsByCsvErrorsResponse'
  index: Scalars['Int']
  message: Scalars['String']
  record: Scalars['JSON']
}

export type UpsertGoalsByCsvResponse = {
  __typename?: 'upsertGoalsByCsvResponse'
  errors: Array<UpsertGoalsByCsvErrorsResponse>
  success: Array<UpsertGoalsByCsvSuccessResponse>
  successCount: Scalars['Int']
}

export type UpsertGoalsByCsvSuccessResponse = {
  __typename?: 'upsertGoalsByCsvSuccessResponse'
  index: Scalars['Int']
  record: Scalars['JSON']
}

export type UpsertGoalsByCsvV2ErrorsResponse = {
  __typename?: 'upsertGoalsByCsvV2ErrorsResponse'
  index: Scalars['Int']
  message: Scalars['String']
  record: Scalars['JSON']
}

export type UpsertGoalsByCsvV2Response = {
  __typename?: 'upsertGoalsByCsvV2Response'
  errors: Array<UpsertGoalsByCsvV2ErrorsResponse>
  success: Array<UpsertGoalsByCsvV2SuccessResponse>
  successCount: Scalars['Int']
}

export type UpsertGoalsByCsvV2SuccessResponse = {
  __typename?: 'upsertGoalsByCsvV2SuccessResponse'
  index: Scalars['Int']
  record: Scalars['JSON']
}

export type ViewResultAsCsvResponse = {
  __typename?: 'viewResultAsCsvResponse'
  csv: Scalars['String']
  error?: Maybe<Scalars['String']>
  hasNext: Scalars['Boolean']
}

export type ConnectionFieldsFragment = {
  __typename?: 'Connection'
  id: string
  organizationId: string
  sourceId: string
  active: boolean
  createdAt: string
  updatedAt: string
  source: { __typename?: 'Source'; id: string; name?: string | null; provider: SourceProviderEnum }
}

export type EltModelFieldsFragment = {
  __typename?: 'EltModel'
  id: string
  type: EltModelTypeEnum
  name: string
  extract?: any | null
  model: CoreModel
  syncedAt?: string | null
  transformedAt?: string | null
  syncInvalidatedAt?: string | null
  transformInvalidatedAt?: string | null
  transform?: {
    __typename?: 'EltModelTransform'
    sql: string
    sqlToDelete?: string | null
    type: EltModelTransformTypeEnum
  } | null
}

export type ExtractCallingMetadataJobSettingFieldsFragment = {
  __typename?: 'ExtractCallingMetadataJobSetting'
  id: string
  organizationId: string
  type: string
  version: string
  promptForSpeechToText?: any | null
  promptToRefineText?: any | null
  promptToExtractMetadata?: any | null
  executedAt?: string | null
  targetEmails?: any | null
  concurrency: number
}

export type InvitationFieldsFragment = {
  __typename?: 'Invitation'
  id: string
  email: string
  provider: IdentityProviderEnum
  role: string
  planForSync: UserPlanEnum
  planForVis: UserPlanEnum
  createdAt: string
}

export type JobQueueFieldsFragment = {
  __typename?: 'JobQueue'
  id: string
  key: string
  jobWorkerId?: string | null
  parentJobQueueId?: string | null
  organizationId?: string | null
  status: JobQueueStatusEnum
  jobName: string
  jobArg: any
  jobResult?: any | null
  processId?: number | null
  message?: string | null
  priority: number
  createdAt: string
  updatedAt: string
  scheduledAt: string
  startedAt?: string | null
  stoppedAt?: string | null
  organization?: { __typename?: 'AdminOrganization'; id: string; name: string } | null
}

export type JobQueueLogFieldsFragment = {
  __typename?: 'JobQueueLog'
  id: string
  message: string
  tags: Array<string>
  level: JobQueueLogLevelEnum
  jobWorkerId: string
  jobQueueId: string
  createdAt: string
}

export type JobWorkerFieldsFragment = {
  __typename?: 'JobWorker'
  id: string
  processId: number
  status: JobWorkerStatusEnum
  childProcessIds: any
  message?: string | null
  createdAt: string
  updatedAt: string
  stoppedAt?: string | null
}

export type MixedUserFieldsFragment = {
  __typename?: 'MixedUser'
  id: string
  resourceUser: {
    __typename?: 'ResourceUser'
    id: string
    name: string
    email: string
    user_group_id?: string | null
    image_url?: string | null
    rank: number
    visibility: boolean
    salescore_user_id?: string | null
    salescore_invitation_id?: string | null
    salesforce_user_id?: string | null
    hubspot_owner_id?: string | null
    hubspot_user_id?: string | null
    zoho_user_id?: string | null
    next_sfa_user_id?: string | null
    google_sheets_user_id?: string | null
  }
  user?: {
    __typename?: 'V2User'
    id: string
    name: string
    imageUrl?: string | null
    role: string
    planForSync: UserPlanEnum
    planForVis: UserPlanEnum
    isManager: boolean
    createdAt: string
    identity: { __typename?: 'Identity'; id: string; email: string; provider: IdentityProviderEnum }
  } | null
  invitation?: {
    __typename?: 'Invitation'
    id: string
    email: string
    provider: IdentityProviderEnum
    role: string
    planForSync: UserPlanEnum
    planForVis: UserPlanEnum
    createdAt: string
  } | null
}

export type ModelPropertyFieldsFragment = {
  __typename?: 'ModelProperty'
  name: string
  type: PropertyTypeEnum
  label: string
  meta?: PropertyMetaEnum | null
  creatable?: boolean | null
  updatable?: boolean | null
  trackable?: boolean | null
  selectOptions?: Array<{
    __typename?: 'ModelPropertySelectOption'
    label: string
    value: string
    color?: string | null
    icon?: string | null
    rule?: ValidationFunction | null
  }> | null
  referenceTo?: Array<{ __typename?: 'PropertyReferenceTo'; modelName: string; key?: string | null }> | null
  validations?: Array<{
    __typename?: 'ValidationRule'
    function: ValidationFunction
    message: string
    feedbackProperties: Array<{ __typename?: 'PropertyNameWithStreamName'; streamName: string; propertyName: string }>
  }> | null
}

export type OrganizationFieldsFragment = {
  __typename?: 'AdminOrganization'
  id: string
  name: string
  currentPlan: SubscriptionPlanEnum
  imageUrl?: string | null
  status: OrganizationStatusEnum
  isEarlyReleaseTarget: boolean
  adminMemo: string
  createdAt: string
  updatedAt: string
  deletedAt?: string | null
  users: Array<{
    __typename?: 'V2User'
    id: string
    name: string
    imageUrl?: string | null
    role: string
    planForSync: UserPlanEnum
    planForVis: UserPlanEnum
    isManager: boolean
    createdAt: string
    identity: { __typename?: 'Identity'; id: string; email: string; provider: IdentityProviderEnum }
  }>
  invitations: Array<{
    __typename?: 'Invitation'
    id: string
    email: string
    provider: IdentityProviderEnum
    role: string
    planForSync: UserPlanEnum
    planForVis: UserPlanEnum
    createdAt: string
  }>
  subscriptions: Array<{
    __typename?: 'Subscription'
    id: string
    plan: SubscriptionPlanEnum
    numberOfSeats: number
    pricePerSeat: number
    note?: string | null
    createdAt: string
    canceledAt?: string | null
  }>
  sources: Array<{
    __typename?: 'Source'
    id: string
    name?: string | null
    provider: SourceProviderEnum
    connection?: { __typename?: 'Connection'; id: string; active: boolean } | null
  }>
  organizationFeatures: Array<{ __typename?: 'OrganizationFeature'; feature: string }>
}

export type OrganizationSettingFieldsFragment = {
  __typename?: 'OrganizationSetting'
  id: string
  accountClosingMonth: number
  monthBeginningDay: number
  userGroupCategoryNames: Array<string>
  csvDownloadEnabledForMember: boolean
  miitelCompanyId?: string | null
  miitelAccessKey?: string | null
  shouldCreateSalesforceOpportunityWhenConvertingLead: boolean
  shouldJoinRecordsInApplication: boolean
  i18nEnabled: boolean
  viewSiderVisibility: boolean
  defaultOpenViewIds: Array<string>
  shouldIgnoreInactiveSourceUser: boolean
  disableEditUsername: boolean
  shouldWarmUpCache: boolean
}

export type PolicyUserRoleFieldsFragment = {
  __typename?: 'PolicyUserRole'
  id: string
  organizationId?: string | null
  name: string
  label?: string | null
  description?: string | null
  updatedById?: string | null
  updatedAt?: string | null
  createdAt?: string | null
}

export type PostgresStatsFieldsFragment = {
  __typename?: 'PostgresStats'
  datid?: string | null
  datname?: string | null
  pid?: string | null
  leader_pid?: string | null
  usesysid?: string | null
  usename?: string | null
  application_name?: string | null
  client_addr?: string | null
  client_hostname?: string | null
  client_port?: string | null
  backend_start?: string | null
  xact_start?: string | null
  query_start?: string | null
  state_change?: string | null
  wait_event_type?: string | null
  wait_event?: string | null
  state?: string | null
  backend_xid?: string | null
  backend_xmin?: string | null
  query?: string | null
  backend_type?: string | null
}

export type PropertyFieldsFragment = {
  __typename?: 'Property'
  name: string
  customized?: boolean | null
  source?: {
    __typename?: 'PropertySource'
    name: string
    meta?: {
      __typename?: 'PropertySourceMeta'
      type?: string | null
      meta?: string | null
      referenceTo?: Array<string> | null
    } | null
  } | null
  destination: { __typename?: 'PropertyDestination'; name: string; sql: string; type: PropertyTypeEnum }
  meta: {
    __typename?: 'PropertyMeta'
    label: string
    meta?: PropertyMetaEnum | null
    typeForMigration?: PropertyTypeForMigrationEnum | null
    referenceTo?: Array<string> | null
    creatable?: boolean | null
    updatable?: boolean | null
    selectOptions?: Array<{
      __typename?: 'PropertySelectOption'
      label: string
      value: string
      color?: string | null
      icon?: string | null
      rule?: ValidationFunction | null
    }> | null
    validations?: Array<{
      __typename?: 'ValidationRule'
      function: ValidationFunction
      message: string
      feedbackProperties: Array<{ __typename?: 'PropertyNameWithStreamName'; streamName: string; propertyName: string }>
    }> | null
  }
}

export type ResourceUserFieldsFragment = {
  __typename?: 'ResourceUser'
  id: string
  name: string
  email: string
  user_group_id?: string | null
  image_url?: string | null
  rank: number
  visibility: boolean
  salescore_user_id?: string | null
  salescore_invitation_id?: string | null
  salesforce_user_id?: string | null
  hubspot_owner_id?: string | null
  hubspot_user_id?: string | null
  zoho_user_id?: string | null
  next_sfa_user_id?: string | null
  google_sheets_user_id?: string | null
}

export type SourceFieldsFragment = {
  __typename?: 'Source'
  id: string
  name?: string | null
  provider: SourceProviderEnum
  connection?: { __typename?: 'Connection'; id: string; active: boolean } | null
}

export type StreamFieldsFragment = {
  __typename?: 'Stream'
  id?: string | null
  name: string
  customized?: boolean | null
  filtered?: boolean | null
  source?: { __typename?: 'StreamSource'; name: string; filters?: Array<string> | null } | null
  destination: {
    __typename?: 'StreamDestination'
    name: string
    filters?: Array<string> | null
    viewDefinitionSQL?: string | null
    materialized?: boolean | null
    derivedTableDefinition?: {
      __typename?: 'StreamDestinationDerivedTableDefinition'
      sql: string
      deleteSql: string
      deleteKey: string
      primaryKey?: string | null
      dependentStreams: Array<{ __typename?: 'DependentStream'; sourceName: string; streamName: string }>
    } | null
  }
  meta: {
    __typename?: 'StreamMeta'
    label: string
    creatable?: boolean | null
    updatable?: boolean | null
    deletable?: boolean | null
  }
  properties: Array<{
    __typename?: 'Property'
    name: string
    customized?: boolean | null
    source?: {
      __typename?: 'PropertySource'
      name: string
      meta?: {
        __typename?: 'PropertySourceMeta'
        type?: string | null
        meta?: string | null
        referenceTo?: Array<string> | null
      } | null
    } | null
    destination: { __typename?: 'PropertyDestination'; name: string; sql: string; type: PropertyTypeEnum }
    meta: {
      __typename?: 'PropertyMeta'
      label: string
      meta?: PropertyMetaEnum | null
      typeForMigration?: PropertyTypeForMigrationEnum | null
      referenceTo?: Array<string> | null
      creatable?: boolean | null
      updatable?: boolean | null
      selectOptions?: Array<{
        __typename?: 'PropertySelectOption'
        label: string
        value: string
        color?: string | null
        icon?: string | null
        rule?: ValidationFunction | null
      }> | null
      validations?: Array<{
        __typename?: 'ValidationRule'
        function: ValidationFunction
        message: string
        feedbackProperties: Array<{
          __typename?: 'PropertyNameWithStreamName'
          streamName: string
          propertyName: string
        }>
      }> | null
    }
  }>
}

export type SubscriptionFieldsFragment = {
  __typename?: 'Subscription'
  id: string
  plan: SubscriptionPlanEnum
  numberOfSeats: number
  pricePerSeat: number
  note?: string | null
  createdAt: string
  canceledAt?: string | null
}

export type SubscriptionStatusFieldsFragment = {
  __typename?: 'SubscriptionStatus'
  numberOfSeats: number
  numberOfRestSeats: number
  numberOfUsers: number
  numberOfInvitations: number
  numberOfFreeUsers: number
  numberOfFreeInvitations: number
}

export type UserFieldsFragment = {
  __typename?: 'V2User'
  id: string
  name: string
  imageUrl?: string | null
  role: string
  planForSync: UserPlanEnum
  planForVis: UserPlanEnum
  isManager: boolean
  createdAt: string
  identity: { __typename?: 'Identity'; id: string; email: string; provider: IdentityProviderEnum }
}

export type ClearJobsMutationVariables = Exact<{ [key: string]: never }>

export type ClearJobsMutation = { __typename?: 'Mutation'; adminClearJobs?: string | null }

export type CreateInvitationMutationVariables = Exact<{
  invitation: AdminInvitationCreateInput
}>

export type CreateInvitationMutation = {
  __typename?: 'Mutation'
  adminCreateInvitation?: {
    __typename?: 'Invitation'
    id: string
    email: string
    provider: IdentityProviderEnum
    role: string
    planForSync: UserPlanEnum
    planForVis: UserPlanEnum
    createdAt: string
  } | null
}

export type CreateOrganizationMutationVariables = Exact<{
  organization: AdminOrganizationInput
}>

export type CreateOrganizationMutation = {
  __typename?: 'Mutation'
  adminCreateOrganization: {
    __typename?: 'AdminOrganization'
    id: string
    name: string
    currentPlan: SubscriptionPlanEnum
    imageUrl?: string | null
    status: OrganizationStatusEnum
    isEarlyReleaseTarget: boolean
    adminMemo: string
    createdAt: string
    updatedAt: string
    deletedAt?: string | null
    users: Array<{
      __typename?: 'V2User'
      id: string
      name: string
      imageUrl?: string | null
      role: string
      planForSync: UserPlanEnum
      planForVis: UserPlanEnum
      isManager: boolean
      createdAt: string
      identity: { __typename?: 'Identity'; id: string; email: string; provider: IdentityProviderEnum }
    }>
    invitations: Array<{
      __typename?: 'Invitation'
      id: string
      email: string
      provider: IdentityProviderEnum
      role: string
      planForSync: UserPlanEnum
      planForVis: UserPlanEnum
      createdAt: string
    }>
    subscriptions: Array<{
      __typename?: 'Subscription'
      id: string
      plan: SubscriptionPlanEnum
      numberOfSeats: number
      pricePerSeat: number
      note?: string | null
      createdAt: string
      canceledAt?: string | null
    }>
    sources: Array<{
      __typename?: 'Source'
      id: string
      name?: string | null
      provider: SourceProviderEnum
      connection?: { __typename?: 'Connection'; id: string; active: boolean } | null
    }>
    organizationFeatures: Array<{ __typename?: 'OrganizationFeature'; feature: string }>
  }
}

export type DeleteOrganizationMutationVariables = Exact<{
  organizationId: Scalars['ID']
}>

export type DeleteOrganizationMutation = {
  __typename?: 'Mutation'
  adminDeleteOrganization?: {
    __typename?: 'AdminOrganization'
    id: string
    name: string
    status: OrganizationStatusEnum
  } | null
}

export type ExecuteScriptMutationMutationVariables = Exact<{
  scriptName: AdminExecuteScriptMutationScriptNameEnum
  organizationId: Scalars['ID']
}>

export type ExecuteScriptMutationMutation = { __typename?: 'Mutation'; adminExecuteScript: string }

export type InvalidateEltModelMutationVariables = Exact<{
  id: Scalars['ID']
  type: Scalars['String']
}>

export type InvalidateEltModelMutation = {
  __typename?: 'Mutation'
  adminInvalidateEltModel?: {
    __typename?: 'JobQueue'
    id: string
    key: string
    jobWorkerId?: string | null
    parentJobQueueId?: string | null
    organizationId?: string | null
    status: JobQueueStatusEnum
    jobName: string
    jobArg: any
    jobResult?: any | null
    processId?: number | null
    message?: string | null
    priority: number
    createdAt: string
    updatedAt: string
    scheduledAt: string
    startedAt?: string | null
    stoppedAt?: string | null
    organization?: { __typename?: 'AdminOrganization'; id: string; name: string } | null
  } | null
}

export type KillQueueMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type KillQueueMutation = {
  __typename?: 'Mutation'
  adminKillQueue?: {
    __typename?: 'JobQueue'
    id: string
    key: string
    jobWorkerId?: string | null
    parentJobQueueId?: string | null
    organizationId?: string | null
    status: JobQueueStatusEnum
    jobName: string
    jobArg: any
    jobResult?: any | null
    processId?: number | null
    message?: string | null
    priority: number
    createdAt: string
    updatedAt: string
    scheduledAt: string
    startedAt?: string | null
    stoppedAt?: string | null
    organization?: { __typename?: 'AdminOrganization'; id: string; name: string } | null
  } | null
}

export type QueueJobMutationVariables = Exact<{
  data: AdminQueueJobInput
}>

export type QueueJobMutation = {
  __typename?: 'Mutation'
  adminQueueJob?: {
    __typename?: 'JobQueue'
    id: string
    key: string
    jobWorkerId?: string | null
    parentJobQueueId?: string | null
    organizationId?: string | null
    status: JobQueueStatusEnum
    jobName: string
    jobArg: any
    jobResult?: any | null
    processId?: number | null
    message?: string | null
    priority: number
    createdAt: string
    updatedAt: string
    scheduledAt: string
    startedAt?: string | null
    stoppedAt?: string | null
    organization?: { __typename?: 'AdminOrganization'; id: string; name: string } | null
  } | null
}

export type RestoreDeletedOrganizationMutationVariables = Exact<{
  organizationId: Scalars['ID']
}>

export type RestoreDeletedOrganizationMutation = {
  __typename?: 'Mutation'
  adminRestoreDeletedOrganization?: { __typename?: 'AdminOrganization'; id: string } | null
}

export type RetryQueueMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type RetryQueueMutation = {
  __typename?: 'Mutation'
  adminRetryQueue?: {
    __typename?: 'JobQueue'
    id: string
    key: string
    jobWorkerId?: string | null
    parentJobQueueId?: string | null
    organizationId?: string | null
    status: JobQueueStatusEnum
    jobName: string
    jobArg: any
    jobResult?: any | null
    processId?: number | null
    message?: string | null
    priority: number
    createdAt: string
    updatedAt: string
    scheduledAt: string
    startedAt?: string | null
    stoppedAt?: string | null
    organization?: { __typename?: 'AdminOrganization'; id: string; name: string } | null
  } | null
}

export type UpdateEltModelFilterMutationVariables = Exact<{
  id: Scalars['ID']
  filters: Array<Scalars['String']> | Scalars['String']
}>

export type UpdateEltModelFilterMutation = {
  __typename?: 'Mutation'
  adminUpdateEltModelFilter: {
    __typename?: 'Connection'
    id: string
    organizationId: string
    sourceId: string
    active: boolean
    createdAt: string
    updatedAt: string
    source: { __typename?: 'Source'; id: string; name?: string | null; provider: SourceProviderEnum }
  }
}

export type UpdateInvitationMutationVariables = Exact<{
  invitation: AdminInvitationInput
}>

export type UpdateInvitationMutation = {
  __typename?: 'Mutation'
  adminUpdateInvitation: {
    __typename?: 'Invitation'
    id: string
    email: string
    provider: IdentityProviderEnum
    role: string
    planForSync: UserPlanEnum
    planForVis: UserPlanEnum
    createdAt: string
  }
}

export type UpdateOrganizationFeaturesMutationVariables = Exact<{
  organizationId: Scalars['ID']
  features: Array<Scalars['String']> | Scalars['String']
}>

export type UpdateOrganizationFeaturesMutation = {
  __typename?: 'Mutation'
  adminUpdateOrganizationFeatures: {
    __typename?: 'AdminOrganization'
    id: string
    organizationFeatures: Array<{ __typename?: 'OrganizationFeature'; id: string; feature: string }>
  }
}

export type UpdateOrganizationMutationVariables = Exact<{
  organizationId: Scalars['ID']
  organization: AdminOrganizationInput
}>

export type UpdateOrganizationMutation = {
  __typename?: 'Mutation'
  adminUpdateOrganization?: {
    __typename?: 'AdminOrganization'
    id: string
    name: string
    status: OrganizationStatusEnum
    adminMemo: string
  } | null
}

export type UpdateOrganizationSettingMutationVariables = Exact<{
  organization: OrganizationInput
  organizationId: Scalars['ID']
}>

export type UpdateOrganizationSettingMutation = {
  __typename?: 'Mutation'
  updateOrganizationV2: {
    __typename?: 'V2Organization'
    id: string
    setting: {
      __typename?: 'OrganizationSetting'
      id: string
      accountClosingMonth: number
      monthBeginningDay: number
      userGroupCategoryNames: Array<string>
      csvDownloadEnabledForMember: boolean
      miitelCompanyId?: string | null
      miitelAccessKey?: string | null
      shouldCreateSalesforceOpportunityWhenConvertingLead: boolean
      shouldJoinRecordsInApplication: boolean
      i18nEnabled: boolean
      viewSiderVisibility: boolean
      defaultOpenViewIds: Array<string>
      shouldIgnoreInactiveSourceUser: boolean
      disableEditUsername: boolean
      shouldWarmUpCache: boolean
    }
  }
}

export type UpdateQueueMutationVariables = Exact<{
  id: Scalars['ID']
  priority: Scalars['Int']
}>

export type UpdateQueueMutation = {
  __typename?: 'Mutation'
  adminUpdateQueue?: {
    __typename?: 'JobQueue'
    id: string
    key: string
    jobWorkerId?: string | null
    parentJobQueueId?: string | null
    organizationId?: string | null
    status: JobQueueStatusEnum
    jobName: string
    jobArg: any
    jobResult?: any | null
    processId?: number | null
    message?: string | null
    priority: number
    createdAt: string
    updatedAt: string
    scheduledAt: string
    startedAt?: string | null
    stoppedAt?: string | null
    organization?: { __typename?: 'AdminOrganization'; id: string; name: string } | null
  } | null
}

export type UpdateSubscriptionMutationVariables = Exact<{
  subscription: SubscriptionInput
  organizationId: Scalars['ID']
}>

export type UpdateSubscriptionMutation = {
  __typename?: 'Mutation'
  adminUpdateSubscription?: {
    __typename?: 'Subscription'
    id: string
    plan: SubscriptionPlanEnum
    numberOfSeats: number
    pricePerSeat: number
    note?: string | null
    createdAt: string
    canceledAt?: string | null
  } | null
}

export type UpdateUserMutationVariables = Exact<{
  user: AdminUserInput
}>

export type UpdateUserMutation = {
  __typename?: 'Mutation'
  adminUpdateUser?: {
    __typename?: 'V2User'
    id: string
    name: string
    imageUrl?: string | null
    role: string
    planForSync: UserPlanEnum
    planForVis: UserPlanEnum
    isManager: boolean
    createdAt: string
    identity: { __typename?: 'Identity'; id: string; email: string; provider: IdentityProviderEnum }
  } | null
}

export type UpsertExtractCallingMetadataJobSettingMutationVariables = Exact<{
  organizationId: Scalars['ID']
  setting: AdminExtractCallingMetadataJobSettingInput
}>

export type UpsertExtractCallingMetadataJobSettingMutation = {
  __typename?: 'Mutation'
  adminUpsertExtractCallingMetadataJobSetting: {
    __typename?: 'ExtractCallingMetadataJobSetting'
    id: string
    organizationId: string
    type: string
    version: string
    promptForSpeechToText?: any | null
    promptToRefineText?: any | null
    promptToExtractMetadata?: any | null
    executedAt?: string | null
    targetEmails?: any | null
    concurrency: number
  }
}

export type FetchConnectionsQueryVariables = Exact<{ [key: string]: never }>

export type FetchConnectionsQuery = {
  __typename?: 'Query'
  adminConnections: Array<{
    __typename?: 'ConnectionForJobQueue'
    id: string
    sourceId: string
    organizationId: string
    sourceProvider: SourceProviderEnum
    active: boolean
  }>
}

export type FetchEltModelsQueryVariables = Exact<{
  organizationId: Scalars['ID']
}>

export type FetchEltModelsQuery = {
  __typename?: 'Query'
  adminEltModels: Array<{
    __typename?: 'EltModel'
    id: string
    type: EltModelTypeEnum
    name: string
    extract?: any | null
    model: CoreModel
    syncedAt?: string | null
    transformedAt?: string | null
    syncInvalidatedAt?: string | null
    transformInvalidatedAt?: string | null
    transform?: {
      __typename?: 'EltModelTransform'
      sql: string
      sqlToDelete?: string | null
      type: EltModelTransformTypeEnum
    } | null
  }>
}

export type FetchExtractCallingMetadataJobSettingQueryVariables = Exact<{
  organizationId: Scalars['ID']
}>

export type FetchExtractCallingMetadataJobSettingQuery = {
  __typename?: 'Query'
  adminExtractCallingMetadataJobSetting?: {
    __typename?: 'ExtractCallingMetadataJobSetting'
    id: string
    organizationId: string
    type: string
    version: string
    promptForSpeechToText?: any | null
    promptToRefineText?: any | null
    promptToExtractMetadata?: any | null
    executedAt?: string | null
    targetEmails?: any | null
    concurrency: number
  } | null
}

export type FetchFeatureFlagsQueryVariables = Exact<{
  features: Array<Scalars['String']> | Scalars['String']
}>

export type FetchFeatureFlagsQuery = {
  __typename?: 'Query'
  adminFeatureFlags: Array<{
    __typename?: 'FeatureFlag'
    feature: string
    organizationName: string
    organizationId: string
  }>
}

export type FetchGptResultQueryVariables = Exact<{
  prompt: Scalars['JSON']
  model: Scalars['String']
}>

export type FetchGptResultQuery = {
  __typename?: 'Query'
  adminGptResult: {
    __typename?: 'GptResult'
    message: string
    completionTokens: number
    totalTokens: number
    promptTokens: number
  }
}

export type FetchJobQueueQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type FetchJobQueueQuery = {
  __typename?: 'Query'
  adminJobQueue: {
    __typename?: 'JobQueue'
    id: string
    key: string
    jobWorkerId?: string | null
    parentJobQueueId?: string | null
    organizationId?: string | null
    status: JobQueueStatusEnum
    jobName: string
    jobArg: any
    jobResult?: any | null
    processId?: number | null
    message?: string | null
    priority: number
    createdAt: string
    updatedAt: string
    scheduledAt: string
    startedAt?: string | null
    stoppedAt?: string | null
    organization?: { __typename?: 'AdminOrganization'; id: string; name: string } | null
  }
}

export type FetchJobQueueLogsQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type FetchJobQueueLogsQuery = {
  __typename?: 'Query'
  adminJobQueue: {
    __typename?: 'JobQueue'
    jobQueueLogs: Array<{
      __typename?: 'JobQueueLog'
      id: string
      message: string
      tags: Array<string>
      level: JobQueueLogLevelEnum
      jobWorkerId: string
      jobQueueId: string
      createdAt: string
    }>
  }
}

export type FetchMixedUsersQueryVariables = Exact<{
  organizationId: Scalars['ID']
}>

export type FetchMixedUsersQuery = {
  __typename?: 'Query'
  mixedUsers: Array<{
    __typename?: 'MixedUser'
    id: string
    resourceUser: {
      __typename?: 'ResourceUser'
      id: string
      name: string
      email: string
      user_group_id?: string | null
      image_url?: string | null
      rank: number
      visibility: boolean
      salescore_user_id?: string | null
      salescore_invitation_id?: string | null
      salesforce_user_id?: string | null
      hubspot_owner_id?: string | null
      hubspot_user_id?: string | null
      zoho_user_id?: string | null
      next_sfa_user_id?: string | null
      google_sheets_user_id?: string | null
    }
    user?: {
      __typename?: 'V2User'
      id: string
      name: string
      imageUrl?: string | null
      role: string
      planForSync: UserPlanEnum
      planForVis: UserPlanEnum
      isManager: boolean
      createdAt: string
      identity: { __typename?: 'Identity'; id: string; email: string; provider: IdentityProviderEnum }
    } | null
    invitation?: {
      __typename?: 'Invitation'
      id: string
      email: string
      provider: IdentityProviderEnum
      role: string
      planForSync: UserPlanEnum
      planForVis: UserPlanEnum
      createdAt: string
    } | null
  }>
}

export type FetchAdminOrganizationQueryVariables = Exact<{
  organizationId: Scalars['ID']
}>

export type FetchAdminOrganizationQuery = {
  __typename?: 'Query'
  adminOrganization?: {
    __typename?: 'AdminOrganization'
    id: string
    name: string
    currentPlan: SubscriptionPlanEnum
    imageUrl?: string | null
    status: OrganizationStatusEnum
    isEarlyReleaseTarget: boolean
    adminMemo: string
    createdAt: string
    updatedAt: string
    deletedAt?: string | null
    organizationSetting: {
      __typename?: 'OrganizationSetting'
      id: string
      accountClosingMonth: number
      monthBeginningDay: number
      userGroupCategoryNames: Array<string>
      csvDownloadEnabledForMember: boolean
      miitelCompanyId?: string | null
      miitelAccessKey?: string | null
      shouldCreateSalesforceOpportunityWhenConvertingLead: boolean
      shouldJoinRecordsInApplication: boolean
      i18nEnabled: boolean
      viewSiderVisibility: boolean
      defaultOpenViewIds: Array<string>
      shouldIgnoreInactiveSourceUser: boolean
      disableEditUsername: boolean
      shouldWarmUpCache: boolean
    }
    subscriptions: Array<{
      __typename?: 'Subscription'
      id: string
      plan: SubscriptionPlanEnum
      numberOfSeats: number
      pricePerSeat: number
      note?: string | null
      createdAt: string
      canceledAt?: string | null
    }>
    jobQueues: Array<{
      __typename?: 'JobQueue'
      id: string
      key: string
      jobWorkerId?: string | null
      parentJobQueueId?: string | null
      organizationId?: string | null
      status: JobQueueStatusEnum
      jobName: string
      jobArg: any
      jobResult?: any | null
      processId?: number | null
      message?: string | null
      priority: number
      createdAt: string
      updatedAt: string
      scheduledAt: string
      startedAt?: string | null
      stoppedAt?: string | null
      organization?: { __typename?: 'AdminOrganization'; id: string; name: string } | null
    }>
    invitations: Array<{
      __typename?: 'Invitation'
      id: string
      email: string
      provider: IdentityProviderEnum
      role: string
      planForSync: UserPlanEnum
      planForVis: UserPlanEnum
      createdAt: string
    }>
    users: Array<{
      __typename?: 'V2User'
      id: string
      name: string
      imageUrl?: string | null
      role: string
      planForSync: UserPlanEnum
      planForVis: UserPlanEnum
      isManager: boolean
      createdAt: string
      identity: { __typename?: 'Identity'; id: string; email: string; provider: IdentityProviderEnum }
    }>
    sources: Array<{
      __typename?: 'Source'
      id: string
      name?: string | null
      provider: SourceProviderEnum
      connection?: { __typename?: 'Connection'; id: string; active: boolean } | null
    }>
    organizationFeatures: Array<{ __typename?: 'OrganizationFeature'; feature: string }>
  } | null
}

export type FetchAdminOrganizationSettingQueryVariables = Exact<{
  organizationId: Scalars['ID']
}>

export type FetchAdminOrganizationSettingQuery = {
  __typename?: 'Query'
  organization: {
    __typename?: 'V2Organization'
    id: string
    setting: {
      __typename?: 'OrganizationSetting'
      id: string
      accountClosingMonth: number
      monthBeginningDay: number
      userGroupCategoryNames: Array<string>
      csvDownloadEnabledForMember: boolean
      miitelCompanyId?: string | null
      miitelAccessKey?: string | null
      shouldCreateSalesforceOpportunityWhenConvertingLead: boolean
      shouldJoinRecordsInApplication: boolean
      i18nEnabled: boolean
      viewSiderVisibility: boolean
      defaultOpenViewIds: Array<string>
      shouldIgnoreInactiveSourceUser: boolean
      disableEditUsername: boolean
      shouldWarmUpCache: boolean
    }
  }
}

export type FetchAdminOrganizationsQueryVariables = Exact<{
  shouldFetchDeleted?: InputMaybe<Scalars['Boolean']>
}>

export type FetchAdminOrganizationsQuery = {
  __typename?: 'Query'
  adminOrganizations: Array<{
    __typename?: 'AdminOrganization'
    id: string
    name: string
    currentPlan: SubscriptionPlanEnum
    imageUrl?: string | null
    status: OrganizationStatusEnum
    isEarlyReleaseTarget: boolean
    adminMemo: string
    createdAt: string
    updatedAt: string
    deletedAt?: string | null
    users: Array<{
      __typename?: 'V2User'
      id: string
      name: string
      imageUrl?: string | null
      role: string
      planForSync: UserPlanEnum
      planForVis: UserPlanEnum
      isManager: boolean
      createdAt: string
      identity: { __typename?: 'Identity'; id: string; email: string; provider: IdentityProviderEnum }
    }>
    invitations: Array<{
      __typename?: 'Invitation'
      id: string
      email: string
      provider: IdentityProviderEnum
      role: string
      planForSync: UserPlanEnum
      planForVis: UserPlanEnum
      createdAt: string
    }>
    subscriptions: Array<{
      __typename?: 'Subscription'
      id: string
      plan: SubscriptionPlanEnum
      numberOfSeats: number
      pricePerSeat: number
      note?: string | null
      createdAt: string
      canceledAt?: string | null
    }>
    sources: Array<{
      __typename?: 'Source'
      id: string
      name?: string | null
      provider: SourceProviderEnum
      connection?: { __typename?: 'Connection'; id: string; active: boolean } | null
    }>
    organizationFeatures: Array<{ __typename?: 'OrganizationFeature'; feature: string }>
  }>
}

export type FetchPolicyUserRolesQueryVariables = Exact<{
  organizationId: Scalars['ID']
}>

export type FetchPolicyUserRolesQuery = {
  __typename?: 'Query'
  policyUserRoles: Array<{
    __typename?: 'PolicyUserRole'
    id: string
    organizationId?: string | null
    name: string
    label?: string | null
    description?: string | null
    updatedById?: string | null
    updatedAt?: string | null
    createdAt?: string | null
  }>
}

export type FetchTableColumnsQueryVariables = Exact<{
  organizationId: Scalars['ID']
}>

export type FetchTableColumnsQuery = {
  __typename?: 'Query'
  adminTableColumns: Array<{
    __typename?: 'PostgresTableColumn'
    table_name: string
    column_name: string
    data_type: string
    table_label?: string | null
    column_label?: string | null
  }>
  destinations: Array<{
    __typename?: 'Destination'
    id: string
    name?: string | null
    provider: DestinationProviderEnum
  }>
}

export type FetchSoqlResultQueryVariables = Exact<{
  organizationId: Scalars['ID']
  query: Scalars['String']
  enableQueryAll: Scalars['Boolean']
}>

export type FetchSoqlResultQuery = { __typename?: 'Query'; adminSoqlResult: Array<any> }

export type FetchSpeechToTextResultQueryVariables = Exact<{
  prompt: Scalars['String']
  s3ObjectPath: Scalars['String']
}>

export type FetchSpeechToTextResultQuery = {
  __typename?: 'Query'
  adminSpeechToTextResult: { __typename?: 'SpeechToTextResult'; transcript: string; s3SignedUrl: string }
}

export type FetchSqlResultQueryVariables = Exact<{
  organizationId: Scalars['ID']
  sql: Scalars['String']
  destinationId?: InputMaybe<Scalars['ID']>
}>

export type FetchSqlResultQuery = {
  __typename?: 'Query'
  adminSqlResult: {
    __typename?: 'PostgresQueryResult'
    rows?: any | null
    rowCount?: number | null
    fields?: Array<{ __typename?: 'PostgresQueryResultField'; name: string }> | null
  }
}

export type FetchUsersQueryVariables = Exact<{
  organizationId: Scalars['ID']
}>

export type FetchUsersQuery = {
  __typename?: 'Query'
  users: Array<{
    __typename?: 'V2User'
    id: string
    name: string
    imageUrl?: string | null
    role: string
    planForSync: UserPlanEnum
    planForVis: UserPlanEnum
    isManager: boolean
    createdAt: string
    identity: { __typename?: 'Identity'; id: string; email: string; provider: IdentityProviderEnum }
  }>
}

export type FetchWorkersPageResourcesQueryVariables = Exact<{
  status: JobQueueStatusEnum
  pastHours: Scalars['Int']
}>

export type FetchWorkersPageResourcesQuery = {
  __typename?: 'Query'
  adminJobWorkers: Array<{
    __typename?: 'JobWorker'
    id: string
    processId: number
    status: JobWorkerStatusEnum
    childProcessIds: any
    message?: string | null
    createdAt: string
    updatedAt: string
    stoppedAt?: string | null
  }>
  adminJobQueueStats: Array<{ __typename?: 'adminJobQueueStatsCount'; status: JobQueueStatusEnum; count: number }>
  adminJobQueues: Array<{
    __typename?: 'JobQueue'
    id: string
    key: string
    jobWorkerId?: string | null
    parentJobQueueId?: string | null
    organizationId?: string | null
    status: JobQueueStatusEnum
    jobName: string
    jobArg: any
    jobResult?: any | null
    processId?: number | null
    message?: string | null
    priority: number
    createdAt: string
    updatedAt: string
    scheduledAt: string
    startedAt?: string | null
    stoppedAt?: string | null
    organization?: { __typename?: 'AdminOrganization'; id: string; name: string } | null
  }>
  adminPostgresStatus: {
    __typename?: 'PostgresStatus'
    datetime: string
    stats: Array<{
      __typename?: 'PostgresStats'
      datid?: string | null
      datname?: string | null
      pid?: string | null
      leader_pid?: string | null
      usesysid?: string | null
      usename?: string | null
      application_name?: string | null
      client_addr?: string | null
      client_hostname?: string | null
      client_port?: string | null
      backend_start?: string | null
      xact_start?: string | null
      query_start?: string | null
      state_change?: string | null
      wait_event_type?: string | null
      wait_event?: string | null
      state?: string | null
      backend_xid?: string | null
      backend_xmin?: string | null
      query?: string | null
      backend_type?: string | null
    }>
  }
}

export const ConnectionFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Connection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'source' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConnectionFieldsFragment, unknown>
export const EltModelFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EltModelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EltModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extract' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sqlToDelete' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncInvalidatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformInvalidatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EltModelFieldsFragment, unknown>
export const ExtractCallingMetadataJobSettingFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExtractCallingMetadataJobSettingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ExtractCallingMetadataJobSetting' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'promptForSpeechToText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'promptToRefineText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'promptToExtractMetadata' } },
          { kind: 'Field', name: { kind: 'Name', value: 'executedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetEmails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'concurrency' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExtractCallingMetadataJobSettingFieldsFragment, unknown>
export const JobQueueFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobQueueFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'JobQueue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobWorkerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentJobQueueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobArg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobResult' } },
          { kind: 'Field', name: { kind: 'Name', value: 'processId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stoppedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobQueueFieldsFragment, unknown>
export const JobQueueLogFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobQueueLogFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'JobQueueLog' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'level' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobWorkerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobQueueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobQueueLogFieldsFragment, unknown>
export const JobWorkerFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobWorkerFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'JobWorker' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'processId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'childProcessIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stoppedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobWorkerFieldsFragment, unknown>
export const ResourceUserFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceUserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ResourceUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salescore_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salescore_invitation_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesforce_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hubspot_owner_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hubspot_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zoho_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'next_sfa_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_sheets_user_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResourceUserFieldsFragment, unknown>
export const UserFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'V2User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForSync' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForVis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManager' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserFieldsFragment, unknown>
export const InvitationFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvitationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invitation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForSync' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForVis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InvitationFieldsFragment, unknown>
export const MixedUserFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MixedUserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MixedUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResourceUserFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invitation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'InvitationFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceUserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ResourceUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salescore_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salescore_invitation_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesforce_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hubspot_owner_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hubspot_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zoho_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'next_sfa_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_sheets_user_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'V2User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForSync' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForVis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManager' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvitationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invitation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForSync' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForVis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MixedUserFieldsFragment, unknown>
export const ModelPropertyFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ModelPropertyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ModelProperty' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'meta' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rule' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'referenceTo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'modelName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'function' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'feedbackProperties' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'streamName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'propertyName' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'creatable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trackable' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ModelPropertyFieldsFragment, unknown>
export const SubscriptionFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfSeats' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricePerSeat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canceledAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscriptionFieldsFragment, unknown>
export const SourceFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Source' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'connection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SourceFieldsFragment, unknown>
export const OrganizationFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrganizationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AdminOrganization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPlan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEarlyReleaseTarget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adminMemo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invitations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'InvitationFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubscriptionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sources' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SourceFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'feature' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'V2User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForSync' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForVis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManager' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvitationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invitation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForSync' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForVis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfSeats' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricePerSeat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canceledAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Source' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'connection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrganizationFieldsFragment, unknown>
export const OrganizationSettingFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrganizationSettingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OrganizationSetting' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountClosingMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthBeginningDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userGroupCategoryNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'csvDownloadEnabledForMember' } },
          { kind: 'Field', name: { kind: 'Name', value: 'miitelCompanyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'miitelAccessKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldCreateSalesforceOpportunityWhenConvertingLead' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldJoinRecordsInApplication' } },
          { kind: 'Field', name: { kind: 'Name', value: 'i18nEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewSiderVisibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultOpenViewIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldIgnoreInactiveSourceUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disableEditUsername' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldWarmUpCache' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrganizationSettingFieldsFragment, unknown>
export const PolicyUserRoleFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PolicyUserRoleFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PolicyUserRole' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PolicyUserRoleFieldsFragment, unknown>
export const PostgresStatsFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostgresStatsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PostgresStats' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'datid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leader_pid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usesysid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'application_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'client_addr' } },
          { kind: 'Field', name: { kind: 'Name', value: 'client_hostname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'client_port' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backend_start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'xact_start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'query_start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state_change' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wait_event_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wait_event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backend_xid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backend_xmin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'query' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backend_type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PostgresStatsFieldsFragment, unknown>
export const PropertyFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Property' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customized' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'source' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meta' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'referenceTo' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destination' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meta' } },
                { kind: 'Field', name: { kind: 'Name', value: 'typeForMigration' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'selectOptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rule' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'referenceTo' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'validations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'function' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'feedbackProperties' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'streamName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'propertyName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'creatable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatable' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PropertyFieldsFragment, unknown>
export const StreamFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StreamFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Stream' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filtered' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'source' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filters' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destination' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'viewDefinitionSQL' } },
                { kind: 'Field', name: { kind: 'Name', value: 'materialized' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'derivedTableDefinition' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deleteSql' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deleteKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'primaryKey' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dependentStreams' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'sourceName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'streamName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'creatable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deletable' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PropertyFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Property' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customized' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'source' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meta' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'referenceTo' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destination' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meta' } },
                { kind: 'Field', name: { kind: 'Name', value: 'typeForMigration' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'selectOptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rule' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'referenceTo' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'validations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'function' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'feedbackProperties' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'streamName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'propertyName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'creatable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatable' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StreamFieldsFragment, unknown>
export const SubscriptionStatusFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionStatusFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubscriptionStatus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfSeats' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfRestSeats' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfUsers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfFreeUsers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfFreeInvitations' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscriptionStatusFieldsFragment, unknown>
export const ClearJobsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'clearJobs' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'adminClearJobs' } }],
      },
    },
  ],
} as unknown as DocumentNode<ClearJobsMutation, ClearJobsMutationVariables>
export const CreateInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invitation' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AdminInvitationCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminCreateInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invitation' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invitation' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'InvitationFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvitationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invitation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForSync' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForVis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateInvitationMutation, CreateInvitationMutationVariables>
export const CreateOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organization' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AdminOrganizationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminCreateOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organization' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organization' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrganizationFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'V2User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForSync' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForVis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManager' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvitationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invitation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForSync' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForVis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfSeats' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricePerSeat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canceledAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Source' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'connection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrganizationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AdminOrganization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPlan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEarlyReleaseTarget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adminMemo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invitations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'InvitationFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubscriptionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sources' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SourceFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'feature' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateOrganizationMutation, CreateOrganizationMutationVariables>
export const DeleteOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminDeleteOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>
export const ExecuteScriptMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'executeScriptMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'scriptName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AdminExecuteScriptMutationScriptNameEnum' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminExecuteScript' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'scriptName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'scriptName' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExecuteScriptMutationMutation, ExecuteScriptMutationMutationVariables>
export const InvalidateEltModelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'invalidateEltModel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminInvalidateEltModel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'JobQueueFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobQueueFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'JobQueue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobWorkerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentJobQueueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobArg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobResult' } },
          { kind: 'Field', name: { kind: 'Name', value: 'processId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stoppedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InvalidateEltModelMutation, InvalidateEltModelMutationVariables>
export const KillQueueDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'killQueue' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminKillQueue' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'JobQueueFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobQueueFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'JobQueue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobWorkerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentJobQueueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobArg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobResult' } },
          { kind: 'Field', name: { kind: 'Name', value: 'processId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stoppedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<KillQueueMutation, KillQueueMutationVariables>
export const QueueJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'queueJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AdminQueueJobInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminQueueJob' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'JobQueueFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobQueueFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'JobQueue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobWorkerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentJobQueueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobArg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobResult' } },
          { kind: 'Field', name: { kind: 'Name', value: 'processId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stoppedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QueueJobMutation, QueueJobMutationVariables>
export const RestoreDeletedOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'restoreDeletedOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminRestoreDeletedOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RestoreDeletedOrganizationMutation, RestoreDeletedOrganizationMutationVariables>
export const RetryQueueDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'retryQueue' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminRetryQueue' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'JobQueueFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobQueueFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'JobQueue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobWorkerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentJobQueueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobArg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobResult' } },
          { kind: 'Field', name: { kind: 'Name', value: 'processId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stoppedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RetryQueueMutation, RetryQueueMutationVariables>
export const UpdateEltModelFilterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateEltModelFilter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminUpdateEltModelFilter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ConnectionFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Connection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'source' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateEltModelFilterMutation, UpdateEltModelFilterMutationVariables>
export const UpdateInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invitation' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AdminInvitationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminUpdateInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invitation' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invitation' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'InvitationFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvitationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invitation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForSync' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForVis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateInvitationMutation, UpdateInvitationMutationVariables>
export const UpdateOrganizationFeaturesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateOrganizationFeatures' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'features' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminUpdateOrganizationFeatures' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'features' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'features' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organizationFeatures' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'feature' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateOrganizationFeaturesMutation, UpdateOrganizationFeaturesMutationVariables>
export const UpdateOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organization' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AdminOrganizationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminUpdateOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organization' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organization' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'adminMemo' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>
export const UpdateOrganizationSettingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateOrganizationSetting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organization' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrganizationInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOrganizationV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organization' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organization' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'setting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrganizationSettingFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrganizationSettingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OrganizationSetting' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountClosingMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthBeginningDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userGroupCategoryNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'csvDownloadEnabledForMember' } },
          { kind: 'Field', name: { kind: 'Name', value: 'miitelCompanyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'miitelAccessKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldCreateSalesforceOpportunityWhenConvertingLead' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldJoinRecordsInApplication' } },
          { kind: 'Field', name: { kind: 'Name', value: 'i18nEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewSiderVisibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultOpenViewIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldIgnoreInactiveSourceUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disableEditUsername' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldWarmUpCache' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateOrganizationSettingMutation, UpdateOrganizationSettingMutationVariables>
export const UpdateQueueDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateQueue' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'priority' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminUpdateQueue' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'priority' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'priority' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'JobQueueFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobQueueFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'JobQueue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobWorkerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentJobQueueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobArg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobResult' } },
          { kind: 'Field', name: { kind: 'Name', value: 'processId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stoppedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateQueueMutation, UpdateQueueMutationVariables>
export const UpdateSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subscription' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SubscriptionInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminUpdateSubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscription' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subscription' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubscriptionFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfSeats' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricePerSeat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canceledAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>
export const UpdateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'user' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AdminUserInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminUpdateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'user' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'user' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'V2User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForSync' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForVis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManager' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>
export const UpsertExtractCallingMetadataJobSettingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertExtractCallingMetadataJobSetting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'setting' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AdminExtractCallingMetadataJobSettingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminUpsertExtractCallingMetadataJobSetting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'setting' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'setting' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ExtractCallingMetadataJobSettingFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExtractCallingMetadataJobSettingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ExtractCallingMetadataJobSetting' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'promptForSpeechToText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'promptToRefineText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'promptToExtractMetadata' } },
          { kind: 'Field', name: { kind: 'Name', value: 'executedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetEmails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'concurrency' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertExtractCallingMetadataJobSettingMutation,
  UpsertExtractCallingMetadataJobSettingMutationVariables
>
export const FetchConnectionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchConnections' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminConnections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sourceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sourceProvider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchConnectionsQuery, FetchConnectionsQueryVariables>
export const FetchEltModelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchEltModels' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminEltModels' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EltModelFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EltModelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EltModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extract' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sql' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sqlToDelete' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncInvalidatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transformInvalidatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchEltModelsQuery, FetchEltModelsQueryVariables>
export const FetchExtractCallingMetadataJobSettingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchExtractCallingMetadataJobSetting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminExtractCallingMetadataJobSetting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ExtractCallingMetadataJobSettingFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExtractCallingMetadataJobSettingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ExtractCallingMetadataJobSetting' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'promptForSpeechToText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'promptToRefineText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'promptToExtractMetadata' } },
          { kind: 'Field', name: { kind: 'Name', value: 'executedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetEmails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'concurrency' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FetchExtractCallingMetadataJobSettingQuery,
  FetchExtractCallingMetadataJobSettingQueryVariables
>
export const FetchFeatureFlagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchFeatureFlags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'features' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminFeatureFlags' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'features' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'features' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'feature' } },
                { kind: 'Field', name: { kind: 'Name', value: 'organizationName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchFeatureFlagsQuery, FetchFeatureFlagsQueryVariables>
export const FetchGptResultDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchGptResult' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'prompt' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'JSON' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'model' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminGptResult' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'prompt' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'prompt' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'model' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'model' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completionTokens' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalTokens' } },
                { kind: 'Field', name: { kind: 'Name', value: 'promptTokens' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchGptResultQuery, FetchGptResultQueryVariables>
export const FetchJobQueueDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchJobQueue' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminJobQueue' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'JobQueueFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobQueueFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'JobQueue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobWorkerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentJobQueueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobArg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobResult' } },
          { kind: 'Field', name: { kind: 'Name', value: 'processId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stoppedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchJobQueueQuery, FetchJobQueueQueryVariables>
export const FetchJobQueueLogsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchJobQueueLogs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminJobQueue' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobQueueLogs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'JobQueueLogFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobQueueLogFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'JobQueueLog' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'level' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobWorkerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobQueueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchJobQueueLogsQuery, FetchJobQueueLogsQueryVariables>
export const FetchMixedUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchMixedUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mixedUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MixedUserFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceUserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ResourceUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_group_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image_url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salescore_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salescore_invitation_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesforce_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hubspot_owner_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hubspot_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zoho_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'next_sfa_user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_sheets_user_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'V2User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForSync' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForVis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManager' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvitationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invitation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForSync' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForVis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MixedUserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MixedUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResourceUserFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invitation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'InvitationFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchMixedUsersQuery, FetchMixedUsersQueryVariables>
export const FetchAdminOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchAdminOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrganizationFields' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organizationSetting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrganizationSettingFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscriptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubscriptionFields' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobQueues' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'JobQueueFields' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invitations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'InvitationFields' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'V2User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForSync' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForVis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManager' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvitationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invitation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForSync' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForVis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfSeats' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricePerSeat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canceledAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Source' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'connection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrganizationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AdminOrganization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPlan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEarlyReleaseTarget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adminMemo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invitations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'InvitationFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubscriptionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sources' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SourceFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'feature' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrganizationSettingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OrganizationSetting' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountClosingMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthBeginningDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userGroupCategoryNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'csvDownloadEnabledForMember' } },
          { kind: 'Field', name: { kind: 'Name', value: 'miitelCompanyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'miitelAccessKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldCreateSalesforceOpportunityWhenConvertingLead' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldJoinRecordsInApplication' } },
          { kind: 'Field', name: { kind: 'Name', value: 'i18nEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewSiderVisibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultOpenViewIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldIgnoreInactiveSourceUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disableEditUsername' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldWarmUpCache' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobQueueFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'JobQueue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobWorkerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentJobQueueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobArg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobResult' } },
          { kind: 'Field', name: { kind: 'Name', value: 'processId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stoppedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchAdminOrganizationQuery, FetchAdminOrganizationQueryVariables>
export const FetchAdminOrganizationSettingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchAdminOrganizationSetting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'setting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrganizationSettingFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrganizationSettingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OrganizationSetting' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountClosingMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthBeginningDay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userGroupCategoryNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'csvDownloadEnabledForMember' } },
          { kind: 'Field', name: { kind: 'Name', value: 'miitelCompanyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'miitelAccessKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldCreateSalesforceOpportunityWhenConvertingLead' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldJoinRecordsInApplication' } },
          { kind: 'Field', name: { kind: 'Name', value: 'i18nEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewSiderVisibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultOpenViewIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldIgnoreInactiveSourceUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disableEditUsername' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldWarmUpCache' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchAdminOrganizationSettingQuery, FetchAdminOrganizationSettingQueryVariables>
export const FetchAdminOrganizationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchAdminOrganizations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shouldFetchDeleted' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminOrganizations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shouldFetchDeleted' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shouldFetchDeleted' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrganizationFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'V2User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForSync' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForVis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManager' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvitationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invitation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForSync' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForVis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfSeats' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricePerSeat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canceledAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Source' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'connection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrganizationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AdminOrganization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPlan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEarlyReleaseTarget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adminMemo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invitations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'InvitationFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubscriptionFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sources' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SourceFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'feature' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchAdminOrganizationsQuery, FetchAdminOrganizationsQueryVariables>
export const FetchPolicyUserRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchPolicyUserRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'policyUserRoles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PolicyUserRoleFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PolicyUserRoleFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PolicyUserRole' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedById' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchPolicyUserRolesQuery, FetchPolicyUserRolesQueryVariables>
export const FetchTableColumnsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchTableColumns' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminTableColumns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'table_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'column_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'table_label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'column_label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destinations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchTableColumnsQuery, FetchTableColumnsQueryVariables>
export const FetchSoqlResultDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchSoqlResult' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'enableQueryAll' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminSoqlResult' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enableQueryAll' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'enableQueryAll' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchSoqlResultQuery, FetchSoqlResultQueryVariables>
export const FetchSpeechToTextResultDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchSpeechToTextResult' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'prompt' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 's3ObjectPath' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminSpeechToTextResult' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'prompt' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'prompt' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 's3ObjectPath' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 's3ObjectPath' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'transcript' } },
                { kind: 'Field', name: { kind: 'Name', value: 's3SignedUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchSpeechToTextResultQuery, FetchSpeechToTextResultQueryVariables>
export const FetchSqlResultDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchSqlResult' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sql' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'destinationId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminSqlResult' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sql' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sql' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'destinationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'destinationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rows' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rowCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchSqlResultQuery, FetchSqlResultQueryVariables>
export const FetchUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'V2User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForSync' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planForVis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isManager' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchUsersQuery, FetchUsersQueryVariables>
export const FetchWorkersPageResourcesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchWorkersPageResources' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'JobQueueStatusEnum' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pastHours' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminJobWorkers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'JobWorkerFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminJobQueueStats' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminJobQueues' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pastHours' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pastHours' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'JobQueueFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminPostgresStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'datetime' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stats' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PostgresStatsFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobWorkerFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'JobWorker' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'processId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'childProcessIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stoppedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobQueueFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'JobQueue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobWorkerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentJobQueueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobArg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobResult' } },
          { kind: 'Field', name: { kind: 'Name', value: 'processId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stoppedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostgresStatsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PostgresStats' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'datid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leader_pid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usesysid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'application_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'client_addr' } },
          { kind: 'Field', name: { kind: 'Name', value: 'client_hostname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'client_port' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backend_start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'xact_start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'query_start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state_change' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wait_event_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wait_event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backend_xid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backend_xmin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'query' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backend_type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchWorkersPageResourcesQuery, FetchWorkersPageResourcesQueryVariables>
